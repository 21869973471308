import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Dashboard from './Dashboard';

const ProfileMain: React.FC = () => {
  const { urlParams } = useParams();

  return (
    <Fragment>
      <Dashboard urlParams={urlParams!} />
    </Fragment>
  );
};

export default ProfileMain;
