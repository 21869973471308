import { ReactNode } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { lightBlue, deepOrange, amber } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: lightBlue[700],
      main: lightBlue[900],
    },
    secondary: {
      light: deepOrange[300],
      main: deepOrange[900],
    },
    warning: {
      main: amber[700],
    },
  },
});

const ECMSTheme: React.FC<{ children: ReactNode }> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export { theme };
export default ECMSTheme;
