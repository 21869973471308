import { ReactNode } from 'react';
import { DragEvent, Fragment } from 'react';
import { ICONS } from '../../config/config';
// import * as lodash from 'lodash';
import { TreeFormElement } from '../../config/types';
import { styled } from '@mui/material/styles';

const DragArea = styled((props: any) => <div {...props} />)(({ _theme }) => ({
  '&': {
    width: '100%',
  },
  '&.hide': {
    transition: '0.01s',
    transform: 'translateX(-9999px)',
  },
}));

type Props = {
  draggable: boolean;
  order: number;
  parentIndex: number;
  setFormElementConfig: (
    formElementConfig:
      | TreeFormElement[]
      | ((prevState: TreeFormElement[]) => TreeFormElement[])
  ) => void;
  startDragElementOrder: number | null;
  setStartDragElementOrder: (index: number | null) => void;
  children: ReactNode;
};

const DragnDrop: React.FC<Props> = ({
  draggable,
  order,
  parentIndex,
  setFormElementConfig,
  startDragElementOrder,
  setStartDragElementOrder,
  children,
}) => {
  const dragStartHandler = (event: DragEvent<HTMLDivElement>) => {
    setStartDragElementOrder(order);
    event.currentTarget.classList.add('hide');
  };

  const dragLeaveHandler = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.background = 'inherit';
  };

  const dragEndHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.classList.remove('hide');
  };

  const dragOverHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.style.background = 'lightgray';
  };

  const dragDropHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setFormElementConfig((prevState: TreeFormElement[]) => {
      const newState = [...prevState].map(
        (childStateElement: TreeFormElement, index: number) => {
          if (index !== parentIndex) return childStateElement;

          // childStateElement['children']!.map(
          //   (childFormElement: TreeFormElement[]) => {
          //     if (
          //       childFormElement[0]['context']['order'] ===
          //       startDragElementOrder
          //     ) {
          //       childFormElement.forEach(
          //         (childFormElementNode: TreeFormElement) => {
          //           childFormElementNode['context']['order'] = order;
          //         }
          //       );
          //     } else if (childFormElement[0]['context']['order'] === order) {
          //       childFormElement.forEach(
          //         (childFormElementNode: TreeFormElement) => {
          //           childFormElementNode['context']['order'] =
          //             startDragElementOrder;
          //         }
          //       );
          //     }
          //     return childFormElement;
          //   }
          // );
          childStateElement['children']!.map(
            (childFormElement: TreeFormElement[]) => {
              if (
                childFormElement[0]['context']['order'] ===
                startDragElementOrder
              ) {
                childFormElement.forEach(
                  (childFormElementNode: TreeFormElement) => {
                    childFormElementNode['context']['order'] =
                      startDragElementOrder! < order ? order + 1 : order - 1;
                  }
                );
              } else if (childFormElement[0]['context']['order'] > order) {
                const newOrder = childFormElement[0]['context']['order'] + 1;
                childFormElement.forEach(
                  (childFormElementNode: TreeFormElement) => {
                    childFormElementNode['context']['order'] = newOrder;
                  }
                );
              }
              return childFormElement;
            }
          );
          return childStateElement;
        }
      );
      newState[parentIndex].children!.sort(
        (a, b) => a[0]['context']['order'] - b[0]['context']['order']
      );

      // making order values from 1 to x
      const newStateReordered = [...newState].map(
        (childStateElement: TreeFormElement, index: number) => {
          if (index !== parentIndex) return childStateElement;
          childStateElement['children']!.map(
            (childFormElement: TreeFormElement[], index: number) => {
              childFormElement.forEach(
                (childFormElementNode: TreeFormElement) => {
                  childFormElementNode['context']['order'] = index + 1;
                }
              );
              return childFormElement;
            }
          );
          return childStateElement;
        }
      );

      return newStateReordered;
    });
    event.currentTarget.style.background = 'inherit';
  };

  return (
    <Fragment>
      {draggable && (
        <DragArea
          draggable={true}
          onDragStart={dragStartHandler}
          onDragLeave={dragLeaveHandler}
          onDragEnd={dragEndHandler}
          onDragOver={dragOverHandler}
          onDrop={dragDropHandler}
        >
          {children}
        </DragArea>
      )}
      {!draggable && <Fragment>{children}</Fragment>}
    </Fragment>
  );
};

const DragElement: React.FC = () => {
  return <ICONS.DragIcon />;
};
export { DragnDrop, DragElement };
