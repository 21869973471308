import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { Button, ButtonProps } from '@mui/material';
import { TreeElement, TreeFormElement } from '../../config/types';
// import simpleStyled from '@emotion/styled';
import { styled } from '@mui/material/styles';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import getCroppedImg from '../../utils/cropImage';
import { SERVER_HTTP } from '../../utils/API';
import { to, imageMimeTypesCheck, imageExtFromMime } from '../../utils/func';

type Props = {
  /** current treeElement we are working with */
  newTreeElement: TreeElement;
  /** current formElement we are working with */
  formElement: TreeFormElement;
  /** Saving 'file' state for formSubmitionHandler() @FormElement.tsx */
  state: (
    formElement: TreeFormElement,
    submitState: any,
    inputRef: React.Ref<HTMLInputElement>,
    setStateFn: any,
    touched?: boolean
  ) => void;
  inputStates: any;
};

// const CropContainer = simpleStyled.div`
//   position: relative;
//   display: block;
//   min-height: 600px;
// `;

const CropContainer = styled((props: any) => <div {...props} />)(
  ({ _theme }) => ({
    '&': {
      position: 'relative',
      minHeight: '600px',
      display: 'block',
    },
  })
);

const ImageContainer = styled((props: any) => <div {...props} />)(
  ({ theme }) => ({
    '&': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
    },
    '& div:first-of-type': {
      marginRight: theme.spacing(1),
    },
  })
);

const EditDoneButton = styled((props: ButtonProps) => <Button {...props} />)(
  ({ theme }) => ({
    '&': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&.active': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&.done': {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  })
);

const InputImageUploadField: React.FC<Props> = ({
  newTreeElement,
  formElement,
  state,
  inputStates,
}) => {
  /** Getting image and image thumbnail sized mentionied in formElement input fields */
  const imageSizeProperties: any = {
    width: null,
    height: null,
    twidth: null,
    theight: null,
  };
  (() => {
    for (const itemId of Object.keys(inputStates)) {
      if (inputStates[itemId].formElement.code === 'imwidth')
        imageSizeProperties.width = inputStates[itemId];
      if (inputStates[itemId].formElement.code === 'imheight')
        imageSizeProperties.height = inputStates[itemId];
      if (inputStates[itemId].formElement.code === 'imtwidth')
        imageSizeProperties.twidth = inputStates[itemId];
      if (inputStates[itemId].formElement.code === 'imtheight')
        imageSizeProperties.theight = inputStates[itemId];
    }
  })();
  /** File place when chosen */
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImageThumb, setSelectedImageThumb] = useState<File | null>(
    null
  );
  /** Sets url for image */
  const [imageUrl, setImageUrl] = useState<any | null>(null);
  const [imageThumbUrl, setImageThumbUrl] = useState<any | null>(null);
  /** Sets picked state when CHOOSE FILE pressed and file is chosen*/
  const [isFilePicked, setIsFilePicked] = useState(false);
  /** Sets FormData with File */
  const [_1, setInputValue] = useState<FormData | null>(null);
  /** on/off image cropper */
  const [isEdit, setIsEdit] = useState(false);
  const [isThumbEdit, setIsThumbEdit] = useState(false);
  /** Saving 'file' state for formSubmitionHandler() @FormElement.tsx */
  const inputRef = useRef<HTMLInputElement>(null);
  /** cropping states */
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  /** Converts File object to URL */
  const fileToURL = async (file: File) => {
    const sourceFile = await new Promise((resolve) => {
      const reader = new FileReader();
      // URL.createObjectURL(file)
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
    return sourceFile;
  };

  /** Executes when image cropping is stopped */
  const onCropComplete = useCallback((_1: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  /** Executes when the DONE button pressed */
  const applyCroppedImage = useCallback(async () => {
    const mime = selectedImage
      ? selectedImage.type
      : newTreeElement.data
      ? newTreeElement.data.immime
      : 'image/jpeg';

    try {
      const croppedImage = await getCroppedImg(
        imageUrl,
        croppedAreaPixels,
        0,
        selectedImage?.name ?? !isThumbEdit ? 'image' : 'image_thumb',
        mime
      );
      if (isEdit) setSelectedImage(croppedImage);
      if (isThumbEdit) setSelectedImageThumb(croppedImage);
      const sourceFile = await fileToURL(croppedImage);
      if (isEdit) setImageUrl(sourceFile);
      if (isThumbEdit) setImageThumbUrl(sourceFile);
    } catch (e) {
      console.error(e);
    }
    setIsEdit(false);
    setIsThumbEdit(false);
  }, [
    croppedAreaPixels,
    imageUrl,
    isEdit,
    isThumbEdit,
    selectedImage,
    newTreeElement.data,
  ]);

  /**
   * Fires when choose file button is pressed
   * @param event
   * @returns
   */
  const changeHandler = async (event: React.FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) {
      return;
    }

    let file = event.currentTarget.files[0];

    // console.log('file picked');
    if (imageMimeTypesCheck(file.type)) {
      const sourceFile = await fileToURL(file);
      setImageUrl(sourceFile);
      setSelectedImage(file);
    } else {
      setSelectedImage(null);
      setImageUrl(null);
    }
    setIsFilePicked(true);
    setIsEdit(false);
  };

  /**
   * Creates FormData for selected File
   * @returns
   */
  const setFormData = () => {
    if (selectedImage || selectedImageThumb) {
      const formData = new FormData();

      if (selectedImage) formData.append('File', selectedImage);
      if (selectedImageThumb) formData.append('File', selectedImageThumb);

      setInputValue(formData);

      return [
        {
          formData,
          data: {
            immime: selectedImage
              ? selectedImage.type
              : selectedImageThumb!.type,
          },
        },
        true,
      ];
    }
    return ['', false];
  };

  /**
   * Does the image/file exist on the server?
   */
  const testCurrentImageUrl = async () => {
    if (newTreeElement.trid !== 0 && !selectedImage && !selectedImageThumb) {
      const [error, _1] = await to(
        SERVER_HTTP.get('/download/' + newTreeElement.trid)
      );
      if (!error) {
        if (imageMimeTypesCheck(newTreeElement.data.immime)) {
          // process.env.REACT_APP_SERVER_URL + 'download/' + newTreeElement.trid
          // removing the first element from path if it is not 'shop' (it is only for 'root' element)
          let path = newTreeElement.trpath!;
          const re = /^\/shop\/.*$/;
          if (!re.exec(path)) {
            path = path.replace(/^(\/.+?\/)(.*)$/, '/$2');
          }
          const image =
            process.env.REACT_APP_SERVER_URL +
            'images' +
            path +
            newTreeElement.trcode +
            '.' +
            imageExtFromMime(newTreeElement.data.immime);

          const imageThumb =
            process.env.REACT_APP_SERVER_URL +
            'images' +
            path +
            newTreeElement.trcode +
            '_thumb' +
            '.' +
            imageExtFromMime(newTreeElement.data.immime);

          setImageUrl(image);

          const [error, _1] = await to(SERVER_HTTP.get(imageThumb));
          if (!error) setImageThumbUrl(imageThumb);
        }
      }
    }
  };

  const toggleEditHandler = () => {
    setIsEdit(!isEdit);
  };

  const toggleThumbEditHandler = () => {
    setIsThumbEdit(!isThumbEdit);
  };

  useEffect(() => {
    /**
     * Saving 'file' state for formSubmitionHandler() @FormElement.tsx
     */
    state(
      formElement,
      setFormData,
      inputRef,
      setInputValue,
      isFilePicked ? true : false
    );
    testCurrentImageUrl();
  }, [isFilePicked, selectedImage, selectedImageThumb]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {newTreeElement.trid !== 0 && imageUrl && (
        <Fragment>
          <p>
            Download link:{' '}
            <a
              href={
                process.env.REACT_APP_SERVER_URL +
                'download/' +
                newTreeElement.trid
              }
              target="_blank"
              rel="noreferrer"
            >
              {process.env.REACT_APP_SERVER_URL +
                'download/' +
                newTreeElement.trid}
            </a>
          </p>
          {imageUrl.match(/^http.*$/) && <p>Image link: {imageUrl}</p>}
        </Fragment>
      )}
      <ImageContainer>
        {imageUrl && (
          <div>
            <img src={imageUrl} alt="" />
          </div>
        )}
        {imageThumbUrl && (
          <div>
            <img src={imageThumbUrl} alt="" />
          </div>
        )}
      </ImageContainer>

      <Button variant="contained" component="label">
        Choose File
        <input type="file" ref={inputRef} hidden onChange={changeHandler} />
      </Button>
      {imageUrl && (
        <Fragment>
          <EditDoneButton
            sx={{ ml: '15px' }}
            variant="contained"
            onClick={toggleEditHandler}
            className={isEdit ? 'active' : ''}
            disabled={isThumbEdit}
          >
            Edit
          </EditDoneButton>
          <EditDoneButton
            sx={{ ml: '15px' }}
            variant="contained"
            onClick={toggleThumbEditHandler}
            className={isThumbEdit ? 'active' : ''}
            disabled={isEdit}
          >
            Edit Thumb
          </EditDoneButton>
        </Fragment>
      )}
      {isFilePicked && selectedImage ? (
        <div>
          <p>Filename: {selectedImage.name}</p>
          <p>Filetype: {selectedImage.type}</p>
          <p>Size in bytes: {selectedImage.size}</p>
          {/* <p>
            lastModifiedDate:{' '}
            {selectedImage.lastModifiedDate.toLocaleDateString()}
          </p> */}
        </div>
      ) : (
        <p>Select a file to show details</p>
      )}
      {isFilePicked && !selectedImage && (
        <p>You should only choose images of jpg or png</p>
      )}
      {imageUrl && (isEdit || isThumbEdit) && (
        <Fragment>
          <CropContainer>
            <div>
              <Cropper
                minZoom={0.1}
                image={imageUrl}
                crop={crop}
                zoom={zoom}
                cropSize={{
                  width:
                    (isThumbEdit
                      ? +imageSizeProperties.twidth?.value
                      : +imageSizeProperties.width?.value) ??
                    +process.env.REACT_APP_IMG_WIDTH_DEFAULT! ??
                    740,
                  height:
                    (isThumbEdit
                      ? +imageSizeProperties.theight?.value
                      : +imageSizeProperties.height?.value) ??
                    +process.env.REACT_APP_IMG_HEIGHT_DEFAULT! ??
                    480,
                }}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <EditDoneButton
              className="done"
              variant="contained"
              onClick={applyCroppedImage}
            >
              Done
            </EditDoneButton>
          </CropContainer>
        </Fragment>
      )}
    </Fragment>
  );
};

export default InputImageUploadField;
