import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../store/index';
import { sendAuthRequest, sendRefreshRequest } from '../store/auth-actions';
import { authActions } from '../store/auth-slice';
import { to } from '../utils/func';

const useAuth = (): [boolean, boolean] => {
  const dispatch: (f: any) => AppDispatch = useDispatch();
  const { token, expires, refreshToken } = useSelector(
    (state: RootState) => state.auth.authData
  );

  const isAuthorized: boolean = useSelector(
    (state: RootState) => state.auth.isAuthorized
  );
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  let logoutTimer = useRef<NodeJS.Timeout | null>(null);
  let refreshTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const authCheck = async () => {
      if (!token) {
        if (logoutTimer.current) window.clearTimeout(logoutTimer.current);
        if (refreshTimer.current) window.clearTimeout(refreshTimer.current);
        dispatch(authActions.logout());
      } else {
        await dispatch(sendAuthRequest(token));
      }
      setIsAuthChecked(true);
    };

    const autoLogout = () => {
      if (token && expires) {
        logoutTimer.current = setTimeout(() => {
          if (refreshTimer.current) window.clearTimeout(refreshTimer.current);
          dispatch(authActions.logout());
        }, new Date(expires).getTime() - new Date().getTime());
      }
    };

    const autoRefresh = () => {
      if (token && expires && refreshToken) {
        const refreshTime =
          new Date(expires).getTime() -
          new Date().getTime() -
          (new Date(expires).getTime() - new Date().getTime()) / 10;
        refreshTimer.current = setTimeout(async () => {
          await to(dispatch(sendRefreshRequest(token, refreshToken)));
          if (logoutTimer.current) window.clearTimeout(logoutTimer.current);
        }, refreshTime);
      }
    };

    authCheck();
    autoLogout();
    autoRefresh();
  }, [token, refreshToken, expires, dispatch]);

  useEffect(() => {
    const checkAuthOnBodyClick = () => {
      if (
        !token ||
        !expires ||
        new Date().getTime() > new Date(expires).getTime()
      ) {
        if (logoutTimer.current) window.clearTimeout(logoutTimer.current);
        if (refreshTimer.current) window.clearTimeout(refreshTimer.current);
        dispatch(authActions.logout());
      }
    };
    document.body.addEventListener('click', checkAuthOnBodyClick);

    return () => {
      document.body.removeEventListener('click', checkAuthOnBodyClick);
    };
  }, [token, expires, dispatch]);

  return [isAuthorized, isAuthChecked];
};

export default useAuth;
