import React, { Fragment, useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { TreeElement, TreeFormElement } from '../../config/types';
// import simpleStyled from '@emotion/styled';
import { SERVER_HTTP } from '../../utils/API';
import { to, fileMimeTypesCheck } from '../../utils/func';

type Props = {
  /** current treeElement we are working with */
  newTreeElement: TreeElement;
  /** current formElement we are working with */
  formElement: TreeFormElement;
  /** Saving 'file' state for formSubmitionHandler() @FormElement.tsx */
  state: (
    formElement: TreeFormElement,
    submitState: any,
    inputRef: React.Ref<HTMLInputElement>,
    setStateFn: any,
    touched?: boolean
  ) => void;
};

const InputFileUploadField: React.FC<Props> = ({
  newTreeElement,
  formElement,
  state,
}) => {
  /** File place when chosen */
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  /** Sets url for file */
  const [fileUrl, setFileUrl] = useState<any | null>(null);
  /** Sets picked state when CHOOSE FILE pressed and file is chosen*/
  const [isFilePicked, setIsFilePicked] = useState(false);
  /** Sets FormData with File */
  const [_1, setInputValue] = useState<FormData | null>(null);
  /** Saving 'file' state for formSubmitionHandler() @FormElement.tsx */
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Fires when choose file button is pressed
   * @param event
   * @returns
   */
  const changeHandler = async (event: React.FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) {
      return;
    }

    let file = event.currentTarget.files[0];

    if (fileMimeTypesCheck(file.type)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }

    setIsFilePicked(true);
  };

  /**
   * Creates FormData for selected File
   * @returns
   */
  const setFormData = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('File', selectedFile);
      setInputValue(formData);
      return [
        {
          formData,
          data: {
            fimime: selectedFile.type,
            finame: selectedFile.name,
          },
        },
        true,
      ];
    }
    return ['', false];
  };

  // const testCurrentImageUrl = useCallback(async () => {
  //   if (newTreeElement.trid !== 0) {
  //     const [error, _1] = await to(
  //       SERVER_HTTP.get('/download/' + newTreeElement.trid)
  //     );
  //     console.log(newTreeElement.trid);
  //     if (!error) setIsEdit(true);
  //   }
  // }, [newTreeElement.trid]);

  /**
   * Does the file exist on the server?
   */
  const testCurrentFileUrl = async () => {
    if (newTreeElement.trid !== 0 && !selectedFile) {
      const [error, _1] = await to(
        SERVER_HTTP.get('/download/' + newTreeElement.trid)
      );
      if (!error) {
        setFileUrl(
          process.env.REACT_APP_SERVER_URL + 'download/' + newTreeElement.trid
        );
      }
    }
  };

  useEffect(() => {
    /**
     * Saving 'file' state for formSubmitionHandler() @FormElement.tsx
     */
    state(
      formElement,
      setFormData,
      inputRef,
      setInputValue,
      isFilePicked ? true : false
    );
    testCurrentFileUrl();
  }, [isFilePicked, selectedFile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {newTreeElement.trid !== 0 && fileUrl && (
        <p>
          Download link:{' '}
          <a
            href={
              process.env.REACT_APP_SERVER_URL +
              'download/' +
              newTreeElement.trid
            }
            target="_blank"
            rel="noreferrer"
          >
            {process.env.REACT_APP_SERVER_URL +
              'download/' +
              newTreeElement.trid}
          </a>
        </p>
      )}
      <Button variant="contained" component="label">
        Choose File
        <input type="file" ref={inputRef} hidden onChange={changeHandler} />
      </Button>
      {isFilePicked && selectedFile ? (
        <div>
          <p>Filename: {selectedFile.name}</p>
          <p>Filetype: {selectedFile.type}</p>
          <p>Size in bytes: {selectedFile.size}</p>
          {/* <p>
            lastModifiedDate:{' '}
            {selectedFile.lastModifiedDate.toLocaleDateString()}
          </p> */}
        </div>
      ) : (
        <p>Select a file to show details</p>
      )}
      {isFilePicked && !selectedFile && <p>You shoudn't choose images</p>}
    </Fragment>
  );
};

export default InputFileUploadField;
