import React, { Fragment, useState, useEffect, useRef } from 'react';
import useInput from '../../hooks/useInput';
import SunEditor from 'suneditor-react';
import CodeMirror from 'codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/xml/xml';
import 'codemirror/keymap/emacs';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import 'codemirror/lib/codemirror.css'; // Import Sun Editor's CSS File
import 'codemirror/theme/material-darker.css';

import { TreeFormElement } from '../../config/types';

type Props = {
  formElement: TreeFormElement;
  defaultValue: string | null;
  state: (
    formElement: TreeFormElement,
    submitState: any,
    inputRef: React.Ref<HTMLInputElement>,
    setStateFn: any,
    touched?: boolean
  ) => void;
  [args: string]: any;
};

const MySunEditor: React.FC<Props> = ({
  formElement,
  defaultValue,
  state,
  ..._rest
}) => {
  const EDITOR_DEFAULT_OPTIONS = {
    position: 'relative',
    minHeight: '300px',
    height: '100%',
    // defaultStyle: "top: 0; bottom: 0; left: 0; right: 0;",
    font: [
      'Arial',
      'Comic Sans MS',
      'Courier New',
      'Impact',
      'Georgia',
      'tahoma',
      'Trebuchet MS',
      'Verdana',
    ],
    buttonList: [
      ['undo', 'redo'],
      ['removeFormat'],
      ['fontColor', 'fontSize', 'formatBlock'],
      ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
      ['blockquote'],
      '/',
      ['outdent', 'indent'],
      ['align', 'horizontalRule', 'list', 'lineHeight'],
      ['table', 'link'],
      ['fullScreen', 'showBlocks', 'codeView'],
      ['preview', 'print'],
    ],
    codeMirror: {
      src: CodeMirror,
      options: {
        mode: 'htmlmixed',
        htmlMode: true,
        lineNumbers: true,
        lineWrapping: true,
        theme: 'material-darker',
      },
    },
  };

  const inputRefFake = useRef<HTMLInputElement>(null);
  const [codeMirrorOpened, setCodeMirrorOpened] = useState(false);
  const { inputRef, inputError, inputSubmit } = useInput(
    formElement.validator!
  );

  const [inputValue, setInputValue] = useState(
    defaultValue ? defaultValue : ''
  );

  const changeHandler = (content: string) => {
    inputRef.current!.value = content;
    setInputValue(content);
    state(formElement, inputSubmit, inputRef, setInputValue);
    // console.log(content);
  };

  useEffect(() => {
    const ref = codeMirrorOpened ? inputRefFake : inputRef;
    const submit = () =>
      codeMirrorOpened ? inputSubmit(inputRefFake) : inputSubmit();
    if (
      !codeMirrorOpened &&
      ref.current &&
      defaultValue &&
      ref.current.value.length === 0 &&
      defaultValue.length > 0
    )
      ref.current.value = defaultValue;
    state(formElement, submit, ref, setInputValue, defaultValue ? true : false);
  }, [codeMirrorOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  const editorOptions = { ...{ value: inputValue }, ...EDITOR_DEFAULT_OPTIONS };
  return (
    <Fragment>
      <input type="hidden" ref={inputRef} />
      <input
        type="hidden"
        ref={inputRefFake}
        value="codemirror"
        required={true}
      />
      <div style={inputError ? { border: '1px solid #ff0000' } : { border: 0 }}>
        <SunEditor
          // height="300px"
          width="100%"
          setOptions={editorOptions}
          onChange={changeHandler}
          toggleCodeView={(isCodeView) => setCodeMirrorOpened(isCodeView)}
        />
      </div>
      {inputError && (
        <p style={{ color: 'red' }}>
          Не забудьте выйти из редактора кода перед сохранением!
        </p>
      )}
    </Fragment>
  );
};
export default MySunEditor;
