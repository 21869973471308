import React from 'react';
import './App.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Loading from './components/UI/Loading';
import Login from './components/pages/login/Login';
import ProfileMain from './components/pages/profile/Main';
import NotFound from './components/pages/404/NotFound';
import PrivateRoute from './components/PrivateRoute';
import useAuth from './hooks/use-auth';
import Notification from './components/UI/Notification';
import { RootState } from './store';
import { useSelector } from 'react-redux';
import ECMSTheme from './components/UI/Theme';

const App: React.FC = () => {
  const [isAuthorized, isAuthChecked] = useAuth();
  const notice = useSelector((state: RootState) => state.notice.notice);
  const error = useSelector((state: RootState) => state.error.error);
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <ECMSTheme>
        <Loading />
        {isAuthChecked && (
          <Routes>
            {!isAuthorized && <Route path="/login" element={<Login />} />}
            {isAuthorized && (
              <Route
                path="/login"
                element={<Navigate to="/profile/dashboard" />}
              />
            )}

            <Route
              path="/profile"
              element={
                <PrivateRoute
                  isAuthorized={isAuthorized}
                  isAuthChecked={isAuthChecked}
                />
              }
            >
              <Route
                path="/profile"
                element={<Navigate to="/profile/dashboard" />}
              />
            </Route>
            <Route
              path="/profile/:urlParams"
              element={
                <PrivateRoute
                  isAuthorized={isAuthorized}
                  isAuthChecked={isAuthChecked}
                />
              }
            >
              <Route path="/profile/:urlParams" element={<ProfileMain />} />
            </Route>
            <Route path="/" element={<Navigate to="/login" />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        )}
        {error && pathname !== '/login' && (
          <Notification severity="error">{error}</Notification>
        )}
        {notice && pathname !== '/login' && (
          <Notification severity="info">{notice}</Notification>
        )}
      </ECMSTheme>
    </React.Fragment>
  );
};

export default App;
