// import * as Validators from '../utils/validators';
import { TreeFormElement } from './types';
// import * as Func from '../utils/func';
import { v4 as uuid } from 'uuid';

// context.id is necessary

const defaultId = uuid();

const FormElementProductCharactsWValues: TreeFormElement[] = [
  {
    id: uuid(),
    name: function (_1) {
      return this.context.charact_name ? this.context.charact_name : 'Name';
    },
    code: 'charact_value',
    defaultValue: null,
    setDefaultValue: function () {
      return this.context.charact_value
        ? this.context.charact_value
        : this.defaultValue;
    },
    errorText: 'Entered an invalid value',
    type: 'text',
    validator: () => true,
    context: { id: defaultId, order: 1 },
    placement: 'options',
    required: false,
    size: {
      xs: 12,
      md: 6,
      lg: 6,
    },
  },
  {
    id: uuid(),
    name: 'Type',
    code: 'charact_name_type',
    setDefaultValue: function () {
      return this.context.charact_name_type
        ? this.context.charact_name_type
        : this.defaultValue;
    },
    defaultValue: null,
    errorText: 'Entered an invalid name type',
    type: 'plainText',
    validator: () => true,
    context: { id: defaultId, order: 1 },
    placement: 'options',
    required: false,
    exclude: true,
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
];

export default FormElementProductCharactsWValues;
