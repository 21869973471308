const validateNumber = (code: string): Boolean => {
  const re = /^[0-9]+$/;
  return re.test(String(code).toLowerCase());
};

const validateUserName = (name: string): Boolean => {
  const re = /^[a-zA-Zа-яА-Я0-9\s]+$/;
  return re.test(String(name).toLowerCase());
};

const validateName = (name: string): Boolean => {
  const re = /^[a-zA-Zа-яА-Я0-9\s\-_\s,.№()]+$/;
  return re.test(String(name).toLowerCase());
};

const validateCode = (code: string): Boolean => {
  // const re =
  //   /^([a-zA-Zа-яА-Я0-9]([a-zA-Zа-яА-Я0-9\-_\s]+)?[a-zA-Zа-яА-Я0-9])|([a-zA-Zа-яА-Я0-9])$/;
  // const re = /^([^\-_\s]+)?[a-zA-Zа-яА-Я0-9\-_\s]+([^\-_\s]+)?$/;
  const re = /^(?![-_\s])[a-zA-Zа-яА-Я0-9\-_\s]+(?<![-_\s])$/;
  const re2 = /--/g;
  return (
    re.test(String(code).toLowerCase()) && !re2.test(String(code).toLowerCase())
  );
};

const validateEmail = (email: string): Boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validateDatetime = (str: string) => {
  const re = /^(\d{4}-\d{2}-\d{2})\s+(\d{2}:\d{2}:\d{2})$/;
  return re.test(String(str).toLowerCase());
};

const validatePassword = (password: string): Boolean => {
  return password.length >= 4;
};

export {
  validateNumber,
  validateUserName,
  validateName,
  validateCode,
  validateEmail,
  validateDatetime,
  validatePassword,
};
