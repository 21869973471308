import * as Validators from '../utils/validators';
import { TreeFormElement } from './types';
import * as Func from '../utils/func';
import { v4 as uuid } from 'uuid';

const FormElementFile: TreeFormElement[] = [
  {
    id: uuid(),
    name: 'Name',
    code: 'trname',
    defaultValue: null,
    errorText: 'Entered an invalid name',
    type: 'text',
    validator: Validators.validateName,
    placement: 'tree',
    required: true,
  },
  {
    id: uuid(),
    name: 'Code',
    code: 'trcode',
    defaultValue: null,
    errorText: 'Entered an invalid code',
    type: 'text',
    validator: Validators.validateCode,
    placement: 'tree',
    required: true,
    size: {
      xs: 12,
      md: 9,
      lg: 9,
    },
    additional: {
      type: 'button',
      name: 'Generate',
      linkedTo: () => FormElementFile[0].id,
      generateFn: Func.transliterate,
      size: {
        xs: 12,
        md: 3,
        lg: 3,
      },
    },
  },
  {
    id: uuid(),
    name: 'Deafult',
    code: 'trdefault',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Hidden',
    code: 'trhidden',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 9,
      lg: 9,
    },
  },
  {
    id: uuid(),
    name: 'Width',
    code: 'imwidth',
    defaultValue: +process.env.REACT_APP_IMG_WIDTH_DEFAULT! ?? null,
    errorText: 'Entered an invalid image width',
    type: 'text',
    validator: Validators.validateNumber,
    placement: 'data',
    required: false,
    size: {
      xs: 6,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Height',
    code: 'imheight',
    defaultValue: +process.env.REACT_APP_IMG_HEIGHT_DEFAULT! ?? null,
    errorText: 'Entered an invalid image height',
    type: 'text',
    validator: Validators.validateNumber,
    placement: 'data',
    required: false,
    size: {
      xs: 6,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Thumb width',
    code: 'imtwidth',
    defaultValue: +process.env.REACT_APP_IMG_THUMB_WIDTH_DEFAULT! ?? null,
    errorText: 'Entered an invalid image thumb width',
    type: 'text',
    validator: Validators.validateNumber,
    placement: 'data',
    required: false,
    size: {
      xs: 6,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Thumb height',
    code: 'imtheight',
    defaultValue: +process.env.REACT_APP_IMG_THUMB_HEIGHT_DEFAULT! ?? null,
    errorText: 'Entered an invalid image thumb height',
    type: 'text',
    validator: Validators.validateNumber,
    placement: 'data',
    required: false,
    size: {
      xs: 6,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Image upload',
    code: 'image',
    defaultValue: null,
    errorText: 'No image uploaded',
    type: 'image',
    validator: () => true,
    placement: 'file',
    required: false,
  },
  // {
  //   id: uuid(),
  //   name: 'MIME type',
  //   code: 'fimime',
  //   defaultValue: null,
  //   errorText: 'Entered an invalid mime',
  //   type: 'text',
  //   validator: () => true,
  //   placement: 'data',
  //   required: false,
  // },
];

export default FormElementFile;
