import { useEffect, useRef } from 'react';

const useSubmitModalForm = (
  isModalSaveButtonClicked: boolean,
  setIsModalSaveButtonClicked: (value: boolean) => void
) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (isModalSaveButtonClicked) {
      if (formRef.current)
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      setIsModalSaveButtonClicked(false);
    }
  }, [isModalSaveButtonClicked, setIsModalSaveButtonClicked, formRef]);

  return { form: formRef };
};

export default useSubmitModalForm;
