import { useState, useRef } from 'react';

const useInput = (validateValue: (value: string) => Boolean) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputError, setInputError] = useState(false);

  const inputSubmit = (ref = inputRef): [string, boolean] => {
    const required = ref.current!.getAttribute('required');
    const enteredValue = ref.current!.value;

    const error =
      (required && enteredValue.trim().length === 0) ||
      !validateValue(enteredValue);

    setInputError(error);

    return [enteredValue, !error];
  };

  return {
    inputRef,
    inputError,
    inputSubmit,
  };
};

export default useInput;
