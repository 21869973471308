import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  localStorageSetAuthData,
  localStorageGetAuthData,
  localStorageRemoveAuthData,
} from './auth-actions';

interface Auth {
  isAuthorized: boolean;
  authData: AuthData;
}

interface AuthData {
  token: string | null;
  expires: string | null;
  refreshToken: string | null;
}

const initialAuthData = localStorageGetAuthData();
const initialAuthState: Auth = {
  isAuthorized: false,
  authData: {
    token: initialAuthData[0],
    expires: initialAuthData[1],
    refreshToken: initialAuthData[2],
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    auth(state) {
      const [token, _expires, _refreshToken] = localStorageGetAuthData();
      if (!token) {
        state.isAuthorized = false;
        return;
      }

      state.isAuthorized = true;
    },
    login(
      state,
      action: PayloadAction<{
        token: string;
        expires: string;
        refreshToken: string;
      }>
    ) {
      const authData = {
        token: action.payload.token,
        expires: action.payload.expires,
        refreshToken: action.payload.refreshToken,
      };

      state.isAuthorized = true;
      state.authData = authData;
      localStorageSetAuthData(authData);
      // const [token, expires] = localStorageGetTokenData();
    },
    logout(state) {
      localStorageRemoveAuthData();
      state.authData = {
        token: null,
        expires: null,
        refreshToken: null,
      };
      state.isAuthorized = false;
      // localStorage.removeItem('token');
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
