import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';

const ModalDialog: React.FC<{
  state: boolean;
  toggleDialogState: () => void;
  title: string;
  message?: string;
  children?: React.ReactNode;
}> = ({ state, toggleDialogState, title, message = '', children }) => {
  const [isModalSaveButtonClicked, setIsModalSaveButtonClicked] =
    useState(false);
  const [isModalClosed, setIsModalClosed] = useState(false);

  useEffect(() => {
    if (isModalClosed) {
      setIsModalClosed(false);
      toggleDialogState();
    }
  }, [isModalClosed, toggleDialogState]);

  const handleConfirm = () => {
    setIsModalSaveButtonClicked(true);
  };

  const handleNotConfirm = () => {
    toggleDialogState();
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        isModalSaveButtonClicked,
        setIsModalSaveButtonClicked,
        setIsModalClosed,
      });
    }
    return child;
  });

  return (
    <div>
      <Dialog maxWidth={false} open={state} onClose={handleNotConfirm}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {message.length !== 0 && (
            <DialogContentText>{message}</DialogContentText>
          )}
          {childrenWithProps}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNotConfirm}>Cancel</Button>
          <Button onClick={handleConfirm}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDialog;
