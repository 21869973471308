import { createSlice } from '@reduxjs/toolkit';

interface CustomNotice {
  notice: string | null;
}

const initialnoticeState: CustomNotice = {
  notice: null,
};

const noticeSlice = createSlice({
  name: 'notice',
  initialState: initialnoticeState,
  reducers: {
    setNotice(state, action) {
      state.notice = action.payload.toUpperCase();
    },
    clearNotice(state) {
      state.notice = null;
    },
  },
});

export type { CustomNotice };
export const noticeActions = noticeSlice.actions;
export default noticeSlice.reducer;
