import React, { Fragment, useState } from 'react';
import { Container, Grid } from '@mui/material';
import useSubmitModalForm from '../../../../../../hooks/use-submitmodalform';
import { useDispatch } from 'react-redux';
import {
  insertTreeElement,
  updateTreeElement,
} from '../../../../../../store/tree-actions';
import ElementMap from './ElementMap';
import * as lodash from 'lodash';
import { validateDatetime } from '../../../../../../utils/validators';
import { extractDateAndTimeFromString } from '../../../../../../utils/func';
// types
import { TreeElement, TreeFormElement } from '../../../../../../config/types';
import { AppDispatch } from '../../../../../../config/types';
//config
import {
  BLANK_TREE_ELEMENT,
  TRCLASSES,
  TREE_ELEMENT_FORM,
} from '../../../../../../config/config';

type Props = {
  trclass: number;
  parentElement: TreeElement;
  action: string;
  isModalSaveButtonClicked: boolean;
  setIsModalSaveButtonClicked: (value: boolean) => void;
  setIsModalClosed: (value: boolean) => void;
};

const FormElement: React.FC<Props> = ({
  trclass,
  parentElement,
  action,
  isModalSaveButtonClicked,
  setIsModalSaveButtonClicked,
  setIsModalClosed,
}) => {
  const dispatch: (f: any) => AppDispatch = useDispatch();

  const newTreeElement: TreeElement =
    action === 'add' ? { ...BLANK_TREE_ELEMENT } : { ...parentElement };

  if (action === 'add') newTreeElement.trclass = trclass;
  if (
    action === 'add' &&
    parentElement.options &&
    Array.isArray(parentElement.options) &&
    parentElement.options.length > 0 &&
    trclass === TRCLASSES['product']
  ) {
    newTreeElement.options = parentElement.options;
  }

  const { form } = useSubmitModalForm(
    isModalSaveButtonClicked,
    setIsModalSaveButtonClicked
  );

  const [inputStates, setInputStates] = useState<any>({});

  const currentFormElementConfig: TreeFormElement[] = lodash.cloneDeep(
    TREE_ELEMENT_FORM[trclass]
  );

  const defaultFormElementConfig: TreeFormElement[] = lodash.cloneDeep(
    TREE_ELEMENT_FORM[trclass]
  );

  const [formElementConfig, setFormElementConfig] = useState<TreeFormElement[]>(
    currentFormElementConfig
  );

  const stateCallBack = (
    formElement: TreeFormElement,
    state: any,
    inputRef: React.Ref<HTMLInputElement>,
    setStateFn: any,
    touched: boolean = true
  ) => {
    const [value, isValid] = touched ? state(inputRef) : ['', false];

    // if (formElement.type === 'textEditor') {
    //   console.log(
    //     formElement.id,
    //     value,
    //     isValid,
    //     (inputRef as React.RefObject<HTMLInputElement>)?.current!.value
    //   );
    // }

    setInputStates((prevState: any) => {
      return {
        ...prevState,
        [formElement.id]: {
          formElement,
          ref: inputRef,
          value,
          isValid,
          state: state,
          setState: setStateFn,
        },
      };
    });
  };

  const generateFormInputValue = (
    generateFn: (value: string) => string,
    formElement: TreeFormElement
  ) => {
    const idSourceElement = formElement.additional.linkedTo();
    const sourceState = inputStates[idSourceElement];

    inputStates[formElement.id].ref.current.value = generateFn(
      sourceState.value
    );
    if (typeof inputStates[formElement.id].setState === 'function')
      inputStates[formElement.id].setState(generateFn(sourceState.value));
    stateCallBack(
      formElement,
      inputStates[formElement.id].state,
      inputStates[formElement.id].ref,
      inputStates[formElement.id].setState,
      true
    );
  };

  const getFormElementOptions = (formElement: TreeFormElement) => {
    let options: any = [];
    let isOptionsValid = true;
    formElement.children!.forEach(
      (formElementChildrenArrayElement: TreeFormElement[]) => {
        let optionsObject: any = {};
        formElementChildrenArrayElement.forEach(
          (formElementChildrenElement: TreeFormElement) => {
            if (formElementChildrenElement.exclude) return;
            if (
              !inputStates[formElementChildrenElement.id].isValid &&
              inputStates[formElementChildrenElement.id].value.length > 0
            )
              isOptionsValid = false;
            // optionsObject.id = formElementChildrenElement.context.id;
            optionsObject = {
              ...formElementChildrenElement.context,
              ...optionsObject,
            };
            optionsObject[formElementChildrenElement.code] =
              inputStates[formElementChildrenElement.id].value;
          }
        );
        options.push(optionsObject);
      }
    );

    options = options.filter((option: any) => {
      let isOptionEmpty = true;
      Object.keys(option).forEach((optionProperty: string) => {
        if (
          optionProperty !== 'id' &&
          optionProperty !== 'order' &&
          option[optionProperty].length > 0
        )
          isOptionEmpty = false;
      });
      return !isOptionEmpty ? option : null;
    });

    return [isOptionsValid, options];
  };

  const formSubmitionHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log('form submit');
    let formIsValid: boolean = true;
    let data: any = {};
    let fileData: any | null = null;
    let optionsData: any = {
      isOptionsValid: true,
      options: [],
    };
    // return;
    formElementConfig.forEach((formElement: TreeFormElement) => {
      if (formElement.type === 'nested') {
        const [isOptionsValid, options] = getFormElementOptions(formElement);
        optionsData.isOptionsValid = isOptionsValid;
        optionsData.options = options;
        return;
      }
      const [value, isValid] =
        formElement.type !== 'checkbox'
          ? inputStates[formElement.id].state()
          : [
              inputStates[formElement.id].value,
              inputStates[formElement.id].isValid,
            ];
      if (
        (!isValid && (formElement.required || value === 'codemirror')) ||
        !optionsData.isOptionsValid
      ) {
        formIsValid = false;
        return;
      }

      if (formElement.placement === 'tree')
        (newTreeElement as any)[formElement.code] =
          formElement.type === 'number' ? +value : value;
      if (formElement.placement === 'data')
        data[formElement.code] = formElement.type === 'number' ? +value : value;
      if (formElement.placement === 'file') fileData = value;
    });

    // if treeElement is FILE and action ADD, but there is no file selected -> form is invalid
    if (
      (trclass === TRCLASSES['file'] || trclass === TRCLASSES['image']) &&
      action === 'add' &&
      !fileData
    ) {
      formIsValid = false;
    }

    if (!formIsValid) return;

    if (action === 'add') {
      newTreeElement.trupid = parentElement.trid;
      newTreeElement.trclass = trclass;
      newTreeElement.parent = parentElement;
    }

    newTreeElement.data = {
      ...newTreeElement.data,
      ...data,
    };

    if (optionsData.options.length > 0)
      newTreeElement.options = optionsData.options;

    if (validateDatetime(newTreeElement.trdate!)) {
      const [date, time] = extractDateAndTimeFromString(newTreeElement.trdate!);
      newTreeElement.trdate = date;
      newTreeElement.trtime = time;
    }

    if (newTreeElement.trclass === TRCLASSES['file'] && fileData) {
      newTreeElement.data['fimime'] = fileData.data.fimime;
      newTreeElement.data['finame'] = fileData.data.finame;
    }

    if (newTreeElement.trclass === TRCLASSES['image'] && fileData) {
      newTreeElement.data['immime'] = fileData.data.immime;
    }

    // console.log(inputStates);
    // console.log(fileData);
    // console.log(action, newTreeElement);
    // for (var pair of fileData.formData.entries()) {
    //   console.log(pair[0], pair[1]);
    // }

    if (action === 'add') {
      await dispatch(
        insertTreeElement(newTreeElement, fileData ? fileData.formData : null)
      );
    }
    if (action === 'edit') {
      console.log(newTreeElement);
      await dispatch(
        updateTreeElement(newTreeElement, fileData ? fileData.formData : null)
      );
    }

    setIsModalClosed(true);
  };

  // console.log(treeElement);
  return (
    <Fragment>
      <Container maxWidth="xl" sx={{ width: 1000 }}>
        <form ref={form} onSubmit={formSubmitionHandler} noValidate>
          {newTreeElement.trid && <p>ID: {newTreeElement.trid}</p>}
          {newTreeElement.trpath && <p>Path: {newTreeElement.trpath}</p>}
          {!newTreeElement.trpath && (
            <p>Path: {parentElement.trpath + parentElement.trcode + '/'}</p>
          )}
          <Grid
            container
            spacing={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ElementMap
              action={action}
              newTreeElement={newTreeElement}
              parentElement={parentElement}
              defaultFormElementConfig={defaultFormElementConfig}
              formElementConfig={formElementConfig}
              setFormElementConfig={setFormElementConfig}
              stateCallBack={stateCallBack}
              generateFormInputValue={generateFormInputValue}
              inputStates={inputStates}
            />
          </Grid>
        </form>
      </Container>
    </Fragment>
  );
};

export default FormElement;
