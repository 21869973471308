import { Fragment, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';

import TreeItem from './TreeItem';
import { getTreeChunk } from '../../../../../store/tree-actions';
import { useDispatch, useSelector } from 'react-redux';

// types
import { RootState, TreeElement, TreeMeta } from '../../../../../config/types';
import { AppDispatch } from '../../../../../config/types';

const MyList = styled(List)(`
  background-color: inherit;
  &.level0 {
    background-color: #inherit;
  }
  &.level1 {
    background-color: #f5f5f5;
  }
  &.level2 {
    background-color: #e5e5e5;
  }
  &.level3 {
    background-color: #d5d5d5;
  }
  &.level4 {
    background-color: #c5c5c5;
  }
  &.level5 {
    background-color: #b5b5b5;
  }
`);

const TreeLoop: React.FC<{
  data: any[];
  tree: any[];
  meta: TreeMeta;
  isOpened: number[];
  setOpened: (opened: number[]) => void;
  currentTrid?: number;
  isLoadingLocal: boolean;
  toggleTreeElementDialog: (
    treeElement: TreeElement,
    action: string,
    treeElementType: number
  ) => void;
  startDragElement: TreeElement | null;
  setStartDragElement: (treeElement: TreeElement | null) => void;
}> = ({
  data,
  tree,
  meta,
  isOpened,
  setOpened,
  currentTrid,
  isLoadingLocal,
  toggleTreeElementDialog,
  startDragElement,
  setStartDragElement,
}) => {
  // const classes = useStyles();
  const dispatch: (f: any) => AppDispatch = useDispatch();

  const classOfTheTreeLevel = (): string => {
    let currentLevel: number = 0;

    if (currentTrid && meta[currentTrid]) {
      currentLevel = meta[currentTrid].length;
    }

    if (currentLevel > 5) currentLevel = 0;
    if (currentLevel === 0) return 'level0';
    if (currentLevel === 1) return 'level1';
    if (currentLevel === 2) return 'level2';
    if (currentLevel === 3) return 'level3';
    if (currentLevel === 4) return 'level4';
    if (currentLevel === 5) return 'level5';
    return 'level0';
  };

  const refreshTreeChunk = async (currentTreeElement: TreeElement) => {
    // const data: any = await dispatch(
    //   getTreeChunk(currentTreeElement, tree, true)
    // ); // refresh chunk
    // let open: number[] = isOpened.slice();
    // data.forEach((element: any) => {
    //   let index = open.indexOf(element.trid);
    //   if (index !== -1) open.splice(index, 1);
    // });
    // setOpened(open);
    await dispatch(getTreeChunk(currentTreeElement));
  };

  const openTreeChunk = async (currentTreeElement: TreeElement) => {
    let open: number[] = isOpened.slice();
    let index = open.indexOf(currentTreeElement.trid);
    if (index !== -1) {
      open.splice(index, 1);
    } else {
      await dispatch(getTreeChunk(currentTreeElement));
      open.push(currentTreeElement.trid);
    }
    setOpened(open);
  };

  return (
    <Fragment>
      <MyList className={classOfTheTreeLevel()}>
        {data.map((item, index: number) => {
          return (
            <Fragment key={`${item.trid}${index}`}>
              <TreeItem
                key={item.trid}
                treeElement={item}
                isSingle={!item.children || item.children === 0}
                itemIcon={item.trclass}
                title={item.trname || item.trcode}
                secondaryText={item.trcode}
                isOpened={isOpened.includes(item.trid)}
                onOpen={async () => await openTreeChunk(item)}
                onRefresh={async () => await refreshTreeChunk(item)}
                isLoadingLocal={isLoadingLocal}
                toggleTreeElementDialog={toggleTreeElementDialog}
                startDragElement={startDragElement}
                setStartDragElement={setStartDragElement}
              />
              {Array.isArray(item.children) && (
                <Collapse
                  key={`c${item.trid}${index}`}
                  component="li"
                  in={isOpened.includes(item.trid)}
                  timeout={300}
                  unmountOnExit
                >
                  <TreeLoop
                    key={`t${item.trid}${index}`}
                    currentTrid={item.trid}
                    data={item.children}
                    tree={tree}
                    meta={meta}
                    isOpened={isOpened}
                    setOpened={setOpened}
                    isLoadingLocal={isLoadingLocal}
                    toggleTreeElementDialog={toggleTreeElementDialog}
                    startDragElement={startDragElement}
                    setStartDragElement={setStartDragElement}
                  />
                </Collapse>
              )}
            </Fragment>
          );
        })}
      </MyList>
    </Fragment>
  );
};
// const Demo = styled('div')(({ theme }) => ({
//   backgroundColor: theme.palette.background.paper,
// }));

const Tree: React.FC<{
  toggleTreeElementDialog: (
    treeElement: TreeElement,
    action: string,
    treeElementType: number
  ) => void;
}> = ({ toggleTreeElementDialog }) => {
  const dispatch: (f: any) => AppDispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<number[]>([]);
  const [startDragElement, setStartDragElement] = useState<TreeElement | null>(
    null
  );
  const tree = useSelector((state: RootState) => state.tree.tree);
  const meta: TreeMeta = useSelector((state: RootState) => state.tree.meta);
  const isLoadingLocal: boolean = useSelector(
    (state: RootState) => state.loading.isLoadingLocal
  );

  useEffect(() => {
    const loadTreeFirst = async () => {
      if (tree.length === 0) {
        await dispatch(getTreeChunk(null));
      }
    };
    loadTreeFirst();
  }, [dispatch, tree.length, tree]);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: '80%' }}>
      <Grid item xs={12}>
        <TreeLoop
          data={tree}
          tree={tree}
          meta={meta}
          isOpened={isOpen}
          setOpened={setIsOpen}
          isLoadingLocal={isLoadingLocal}
          toggleTreeElementDialog={toggleTreeElementDialog}
          startDragElement={startDragElement}
          setStartDragElement={setStartDragElement}
        />
      </Grid>
    </Box>
  );
};

export default Tree;
