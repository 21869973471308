import API from '../utils/API';
import { userActions } from './user-slice';
import type { User } from './user-slice';
import { errorActions } from './error-slice';
import { noticeActions } from './notice-slice';

export const getUserList = () => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.get('/admin/users')
        .then((response) => {
          return JSON.parse(response.data.users);
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      const users: User[] = await sendRequest();
      dispatch(userActions.setUsers(users));
      // dispatch(errorActions.clearError());
    } catch (error: any) {
      dispatch(errorActions.setError(error.message));
      return;
    }
  };
};

export const insertUser = (newUserData: User) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.post('/admin/users', newUserData)
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      const data = await sendRequest();
      dispatch(noticeActions.setNotice(data.message || 'User added'));
    } catch (error: any) {
      dispatch(errorActions.setError(error.message));
    }
  };
};

export const updateUser = (newUserData: User) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.put('/admin/users', newUserData)
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      const data = await sendRequest();
      dispatch(noticeActions.setNotice(data.message || 'User updated'));
    } catch (error: any) {
      dispatch(errorActions.setError(error.message));
    }
  };
};

export const deleteUser = (adlogin: string) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.delete('/admin/users/' + adlogin)
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error);
        });
    };

    try {
      const data = await sendRequest();
      dispatch(noticeActions.setNotice(data.message || 'User deleted'));
    } catch (error: any) {
      dispatch(errorActions.setError(error.message || 'Some error occured'));
    }
  };
};
