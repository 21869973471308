import Button from '@mui/material/Button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const Confirm: React.FC<{
  state: boolean;
  toggleConfirmState: () => void;
  confirm: (state: boolean) => void;
  title: string;
  message: string;
}> = ({ state, toggleConfirmState, confirm, title, message }) => {
  const handleConfirm = () => {
    // toggleConfirmState();
    confirm(true);
  };

  const handleNotConfirm = () => {
    toggleConfirmState();
    confirm(false);
  };

  return (
    <div>
      <Dialog
        open={state}
        onClose={handleNotConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNotConfirm}>No</Button>
          <Button onClick={handleConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Confirm;
