import { Navigate, Outlet } from 'react-router-dom';
import { RouteComponentProps } from '@reach/router';
// import useAuth from '../hooks/use-auth';

interface RouteProps extends RouteComponentProps {
  isAuthorized: boolean;
  isAuthChecked: boolean;
}

const PrivateRoute: React.FC<RouteProps> = ({
  isAuthorized,
  isAuthChecked,
}) => {
  return isAuthChecked && isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
