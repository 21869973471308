import React, { Fragment, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../Title';
import Tree from './tree/Tree';
import FormElement from './tree/element/FormElement';
import ModalDialog from '../../../UI/ModalDialog';
import Confirm from '../../../UI/Confirm';
// types
import { TRCLASS_NAMES } from '../../../../config/config';
import { TreeElement } from '../../../../config/types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../config/types';
import {
  getTreeElement,
  deleteTreeElement,
} from '../../../../store/tree-actions';

const ProfileStructure: React.FC = () => {
  const dispatch: (f: any) => AppDispatch = useDispatch();
  const [dialogState, setDialogState] = useState<any[] | null>(null);
  const [dialogToggle, setDialogToggle] = useState(false);
  const [confirmState, setConfirmState] = useState(false);

  const toggleTreeElementDialog = async (
    treeElement: TreeElement | any,
    action: string,
    newTreeElementType: number
  ) => {
    treeElement = await dispatch(getTreeElement(treeElement.trid));
    treeElement.trdate = (treeElement.trdate ?? '').replace(
      /^(\d{4})-(\d{2})-(\d{2}).*$/,
      '$1-$2-$3'
    );
    console.log(treeElement);

    if (action === 'add' || action === 'edit') {
      setDialogState([
        action === 'add' ? newTreeElementType : treeElement.trclass,
        treeElement,
        action,
      ]);
      setDialogToggle(true);
    }

    if (action === 'delete') {
      setDialogState([treeElement.trclass, treeElement, action]);
      toggleConfirm();
    }
  };

  const toggleDialogHandler = () => {
    setDialogToggle(!dialogToggle);
  };

  // delete
  const toggleConfirm = () => {
    setConfirmState(!confirmState);
  };

  const deleteTreeElementHandler = async (confirm: boolean) => {
    if (!confirm) return;
    if (dialogState) await dispatch(deleteTreeElement(dialogState[1]));
    toggleConfirm();
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Title>Structure</Title>
          </Paper>
        </Grid>
        {/* Chart */}
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Tree toggleTreeElementDialog={toggleTreeElementDialog} />
          </Paper>
        </Grid>
      </Grid>
      {dialogState && dialogState[2] !== 'delete' && (
        <ModalDialog
          title={TRCLASS_NAMES[dialogState[0]].toUpperCase()}
          state={dialogToggle}
          toggleDialogState={toggleDialogHandler}
        >
          <FormElement
            trclass={dialogState[0]}
            parentElement={dialogState[1]}
            action={dialogState[2]}
            isModalSaveButtonClicked={false} // mocked values
            setIsModalSaveButtonClicked={() => null} // mocked values
            setIsModalClosed={() => null} // mocked values
          />
        </ModalDialog>
      )}
      {dialogState && dialogState[2] === 'delete' && (
        <Confirm
          state={confirmState}
          toggleConfirmState={toggleConfirm}
          confirm={deleteTreeElementHandler}
          title="Confirmation"
          message="Delete element?"
        />
      )}
    </Fragment>
  );
};

export default ProfileStructure;
