import { Fragment } from 'react';
import { Grid, Paper } from '@mui/material';
import Title from './Title';

const DashBoardIndex: React.FC = () => {
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Title>Dashboard</Title>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              pb: 4,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <p>Dashboard is empty for now...</p>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DashBoardIndex;
