import { Fragment, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Menu as MuiMenu, MenuItem, MenuProps } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

// types
import { TreeElement } from '../../config/types';

const Menu = styled((props: MenuProps) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 130,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const InitMenu: React.FC<{
  treeElement: TreeElement;
  menu: any[];
  children?: any;
  toggleTreeElementDialog: (
    treeElement: TreeElement,
    action: string,
    treeElementType: number
  ) => void;
}> = ({ treeElement, menu, children, toggleTreeElementDialog }) => {
  const [anchors, setAnchors] = useState<{ [key: number]: any }>({});

  return (
    <NestedMenu
      treeElement={treeElement}
      menu={menu}
      anchors={anchors}
      setAnchors={setAnchors}
      toggleTreeElementDialog={toggleTreeElementDialog}
    >
      {children}
    </NestedMenu>
  );
};

const NestedMenu: React.FC<{
  treeElement: TreeElement;
  menu: any[];
  anchors: { [key: number]: any };
  setAnchors: (anchors: { [key: number]: any }) => void;
  children?: any;
  level?: number;
  toggleTreeElementDialog: (
    treeElement: TreeElement,
    action: string,
    treeElementType: number
  ) => void;
}> = ({
  treeElement,
  menu,
  anchors,
  setAnchors,
  children,
  level = 0,
  toggleTreeElementDialog,
}) => {
  const submenus: { [key: number]: any[] } = {};
  let hiddenElementsCount = 0;

  const nestedMenuOpenHandler = (event: any, menuId: number) => {
    const menuAnchors = cloneDeep(anchors);
    menuAnchors[menuId] = event.currentTarget;
    setAnchors(menuAnchors);
  };

  const nestedMenuCloseHandler = (menuId: number) => {
    const menuAnchors = cloneDeep(anchors);
    if (menuAnchors[menuId]) delete menuAnchors[menuId];
    setAnchors(menuAnchors);
  };

  const isMenuOpened = (menuId: number) => {
    return anchors[menuId] ? true : false;
  };

  const setNextMenu = (level: number, index: number, element: any) => {
    submenus[level + index + 1] = element.children;
  };

  menu.map((element) => {
    if (element.show && !element.show(treeElement)) {
      hiddenElementsCount++;
    }
    return null;
  });

  return (
    <Fragment>
      {children && (
        <div
          style={{ display: 'inline-block' }}
          onClick={(event) => nestedMenuOpenHandler(event, level)}
        >
          {children}
        </div>
      )}
      {hiddenElementsCount !== menu.length && (
        <Menu
          sx={hiddenElementsCount === menu.length ? { display: 'none' } : {}}
          anchorEl={anchors[level]}
          open={isMenuOpened(level)}
          onClose={() => nestedMenuCloseHandler(level)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <div
            onMouseLeave={() =>
              level > 0 ? nestedMenuCloseHandler(level) : undefined
            }
          >
            {menu.map((element, index) => {
              if (element.show && !element.show(treeElement)) return null;
              return (
                <div key={level + ':' + index}>
                  {element.children && setNextMenu(level, index, element)}
                  <MenuItem
                    onClick={() => {
                      setAnchors({}); // close all menus
                      return element.action
                        ? toggleTreeElementDialog(
                            treeElement,
                            element.action,
                            element.type
                          )
                        : undefined;
                    }}
                    onMouseOver={(event) =>
                      element.children
                        ? nestedMenuOpenHandler(event, level + index + 1)
                        : undefined
                    }
                  >
                    {element.icon && <element.icon />}
                    {element.title}
                  </MenuItem>
                </div>
              );
            })}
          </div>
        </Menu>
      )}
      {Object.keys(submenus).length > 0 &&
        Object.keys(submenus).map((menuId, index) => {
          return (
            <NestedMenu
              key={menuId + '-' + index}
              treeElement={treeElement}
              menu={submenus[+menuId]}
              anchors={anchors}
              setAnchors={setAnchors}
              level={+menuId}
              toggleTreeElementDialog={toggleTreeElementDialog}
            />
          );
        })}
    </Fragment>
  );
};

export default InitMenu;
