import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TreeStore, TreeMeta, TreeElement } from '../config/types';

const initialErrorState: TreeStore = {
  tree: [],
  meta: {},
};

// let meta = [0, 0, 0];
// insertTreeChunk(MOCK_TREE, meta, 'test');
const insertTreeChunk = (
  tree: TreeElement[],
  meta: any[],
  newTreeChunk: TreeElement[]
) => {
  // console.log(tree, meta, value);
  const reducer = (a: any, b: any, index: number) => {
    if (index === meta.length - 1) {
      let mergedChunk;
      // проверка на существующие открытые подразделы
      // чтобы не терять их - вставляем в новый кусок
      if (Array.isArray(a[b].children)) {
        mergedChunk = newTreeChunk.map((newTreeElement: TreeElement) => {
          a[b].children.forEach((oldTreeElement: TreeElement) => {
            if (
              oldTreeElement.trid === newTreeElement.trid &&
              oldTreeElement.children &&
              Array.isArray(oldTreeElement.children)
            )
              newTreeElement.children = oldTreeElement.children;
          });
          return newTreeElement;
        });
      } else {
        mergedChunk = newTreeChunk;
      }
      a[b].children = mergedChunk;
      return mergedChunk;
    }
    return a[b].children;
  };
  meta.reduce(reducer, tree);

  return tree;
};

const updateTreeElement = (
  tree: TreeElement[],
  meta: any[],
  newTreeElementData: TreeElement
) => {
  // console.log(tree, meta, value);
  const reducer = (a: any, b: any, index: number) => {
    if (index === meta.length - 1) {
      newTreeElementData.children =
        a[b].trsort !== newTreeElementData.trsort &&
        Array.isArray(a[b].children)
          ? a[b].children.reverse()
          : a[b].children;
      a[b] = newTreeElementData;
      return newTreeElementData;
    }
    return a[b].children;
  };
  meta.reduce(reducer, tree);

  return tree;
};

const getTreeMeta = (tree: { [key: number | string]: any[] }) => {
  let meta: TreeMeta = {};
  const getTreeMetaRecursive = (
    tree: { [key: number | string]: any[] },
    level: number = 0
  ): any => {
    let children: { [key: number | string]: any[] } = {};
    let i: number = 0;

    Object.keys(tree).forEach((flatLevel: number | string) => {
      tree[flatLevel].forEach((element: any, index) => {
        if (!meta[element.trid]) {
          meta[element.trid] = [];
          if (meta[element.trupid]) {
            meta[element.trid] = [...meta[element.trupid], index];
          } else {
            meta[element.trid].push(index);
          }
        }
        if (element.children && Array.isArray(element.children)) {
          children[i] = element.children;
          i++;
        }
      });
    });

    level++;
    if (Object.keys(children).length > 0) {
      getTreeMetaRecursive(children, level);
    }
  };

  getTreeMetaRecursive(tree);

  return meta;
};

const treeSlice = createSlice({
  name: 'tree',
  initialState: initialErrorState,
  reducers: {
    setTree(
      state,
      action: PayloadAction<{
        trid: number;
        treeChunk: TreeElement[];
      }>
    ) {
      const trid = action.payload.trid;
      const treeChunk = action.payload.treeChunk;
      const metaObject: { [key: number | string]: any[] } = {};

      if (state.tree.length > 0 && trid !== 0) {
        const tree = insertTreeChunk(state.tree, state.meta[trid], treeChunk);
        state.tree = tree;
      } else if (state.tree.length === 0 && trid === 0) {
        metaObject[0] = state.tree;
        state.meta = getTreeMeta(metaObject);
        state.tree = treeChunk;
      }

      metaObject[0] = state.tree;
      state.meta = getTreeMeta(metaObject);
    },
    updateTree(
      state,
      action: PayloadAction<{
        treeElement: TreeElement;
      }>
    ) {
      const tree = updateTreeElement(
        state.tree,
        state.meta[action.payload.treeElement.trid],
        action.payload.treeElement
      );
      state.tree = tree;
    },
  },
});

export const treeActions = treeSlice.actions;
export default treeSlice.reducer;
