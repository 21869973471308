import * as Validators from '../utils/validators';
import { TreeFormElement } from './types';
import * as Func from '../utils/func';
import FormElementProductCharacts from './FormElementProductCharacts';
import { v4 as uuid } from 'uuid';

const FormElementGroup: TreeFormElement[] = [
  {
    id: uuid(),
    name: 'Name',
    code: 'trname',
    defaultValue: null,
    errorText: 'Entered an invalid name',
    type: 'text',
    validator: Validators.validateName,
    placement: 'tree',
    required: true,
  },
  {
    id: uuid(),
    name: 'Code',
    code: 'trcode',
    defaultValue: null,
    errorText: 'Entered an invalid code',
    type: 'text',
    validator: Validators.validateCode,
    placement: 'tree',
    required: true,
    size: {
      xs: 12,
      md: 9,
      lg: 9,
    },
    additional: {
      type: 'button',
      name: 'Generate',
      linkedTo: () => FormElementGroup[0].id,
      generateFn: Func.transliterate,
      size: {
        xs: 12,
        md: 3,
        lg: 3,
      },
    },
  },
  {
    id: uuid(),
    name: 'Hidden',
    code: 'trhidden',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Empty',
    code: 'trempty',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: (value) => {
      return value === 'F' ? 'Sorting ASC' : 'Sorting DESC';
    },
    code: 'trsort',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Keywords',
    code: 'sgwords',
    defaultValue: null,
    errorText: 'Entered an invalid keywords',
    type: 'text',
    validator: () => true,
    placement: 'data',
    required: false,
    rest: {
      multiline: true,
      maxRows: 4,
    },
  },
  {
    id: uuid(),
    name: 'Description',
    code: 'sgdesc',
    defaultValue: null,
    errorText: 'Entered an invalid description',
    type: 'text',
    validator: () => true,
    placement: 'data',
    required: false,
    rest: {
      multiline: true,
      maxRows: 4,
    },
  },
  {
    id: uuid(),
    name: 'Group short',
    code: 'sgabout',
    defaultValue: null,
    errorText: 'Entered an invalid short text',
    type: 'text',
    validator: () => true,
    placement: 'data',
    required: false,
    rest: {
      multiline: true,
      maxRows: 4,
    },
  },
  {
    id: uuid(),
    name: 'Characteristics',
    code: 'characts',
    defaultValue: null,
    type: 'nested',
    placement: 'options',
    multi: true,
    draggable: true,
    children: [FormElementProductCharacts],
  },
];

export default FormElementGroup;
