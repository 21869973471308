import { IMAGE_MIME } from '../config/config';

const to = (promise: any, improved?: any) => {
  return promise
    .then((data: any) => [null, data])
    .catch((error: any) => {
      if (improved) {
        Object.assign(error, improved);
      }

      return [error];
    });
};

const transliterate = (word: string) => {
  const a: { [key: string]: string } = {
    Ё: 'YO',
    Й: 'I',
    Ц: 'TS',
    У: 'U',
    К: 'K',
    Е: 'E',
    Н: 'N',
    Г: 'G',
    Ш: 'SH',
    Щ: 'SCH',
    З: 'Z',
    Х: 'H',
    Ъ: '$',
    ё: 'yo',
    й: 'i',
    ц: 'ts',
    у: 'u',
    к: 'k',
    е: 'e',
    н: 'n',
    г: 'g',
    ш: 'sh',
    щ: 'sch',
    з: 'z',
    х: 'h',
    ъ: '$',
    Ф: 'F',
    Ы: 'I',
    В: 'V',
    А: 'a',
    П: 'P',
    Р: 'R',
    О: 'O',
    Л: 'L',
    Д: 'D',
    Ж: 'ZH',
    Э: 'E',
    ф: 'f',
    ы: 'i',
    в: 'v',
    а: 'a',
    п: 'p',
    р: 'r',
    о: 'o',
    л: 'l',
    д: 'd',
    ж: 'zh',
    э: 'e',
    Я: 'Ya',
    Ч: 'CH',
    С: 'S',
    М: 'M',
    И: 'I',
    Т: 'T',
    Ь: '$',
    Б: 'B',
    Ю: 'YU',
    я: 'ya',
    ч: 'ch',
    с: 's',
    м: 'm',
    и: 'i',
    т: 't',
    ь: '$',
    б: 'b',
    ю: 'yu',
  };

  let str = word
    .split('')
    .map(function (char) {
      return a[char] || char;
    })
    .join('');

  return str
    .replace(/\$/g, '')
    .replace(/\s+/g, '-')
    .replace(/^[-]+/g, '')
    .replace(/^(.+?)[^a-zA-Zа-яА-Я0-9]+$/, '$1')
    .replace(/^[^a-zA-Zа-яА-Я0-9]+(.+?)$/, '$1')
    .replace(/[^a-zA-Zа-яА-Я0-9\-_]/g, '')
    .toLowerCase();
};

const updateBooleanToChar = (obj: {
  [key: string]: boolean | string;
}): { [key: string]: string } => {
  const newObj: { [key: string]: string } = {};
  Object.keys(obj).forEach((key) => {
    newObj[key] = obj[key] ? 'T' : 'F';
  });
  return newObj;
};

const getFormattedDate = (dateString: Date | null) => {
  const date = dateString ? dateString : new Date();
  let dateFormatted =
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2);
  let timeFormatted =
    ('0' + date.getHours()).slice(-2) +
    ':' +
    ('0' + date.getMinutes()).slice(-2) +
    ':' +
    ('0' + date.getSeconds()).slice(-2);

  return { date: dateFormatted, time: timeFormatted };
};

const dateFromString = (date: string, time: string) => {
  const [_1, year, month, day] = /^(\d{4})-(\d{2})-(\d{2}).*$/.exec(date) || [];
  const [_2, hours, minutes, seconds] =
    /^(\d{2}):(\d{2}):(\d{2}).*$/.exec(time) || [];
  return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
};

const extractDateAndTimeFromString = (str: string) => {
  const [_1, date, time] =
    /^(\d{4}-\d{2}-\d{2})\s+(\d{2}:\d{2}:\d{2})$/.exec(str) || [];
  return [date, time];
};

const imageMimeTypesCheck = (mime: string) => {
  const index = IMAGE_MIME.indexOf(mime);
  return index !== -1 ? true : false;
};

const imageExtFromMime = (mime: string) => {
  return mime === 'image/jpeg' ? 'jpg' : 'png';
};

const fileMimeTypesCheck = (mime: string) => {
  const index = IMAGE_MIME.indexOf(mime);
  return index === -1 ? true : false;
};

// const createObject = (...args: any) => {
//   console.log(args);
// };

export {
  to,
  transliterate,
  updateBooleanToChar,
  getFormattedDate,
  dateFromString,
  extractDateAndTimeFromString,
  imageMimeTypesCheck,
  imageExtFromMime,
  fileMimeTypesCheck,
};
