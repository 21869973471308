import { ReactNode } from 'react';
import { DragEvent, Fragment } from 'react';
import { ICONS } from '../../config/config';
// import * as lodash from 'lodash';
import { TreeElement } from '../../config/types';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import useTreeChunk, { TreeChunkWSort } from '../../hooks/use-treechunk';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../config/types';
import { treeActions } from '../../store/tree-slice';
import { updateTreeChunkOrder } from '../../store/tree-actions';

const DragArea = styled((props: any) => <div {...props} />)(({ _theme }) => ({
  '&': {
    width: '100%',
  },
  '&.hide': {
    transition: '0.01s',
    transform: 'translateX(-9999px)',
  },
}));

type Props = {
  isOpened: boolean | undefined;
  treeElement: TreeElement;
  startDragElement: TreeElement | null;
  setStartDragElement: (treeElement: TreeElement | null) => void;
  children: ReactNode;
};

const DragnDrop: React.FC<Props> = ({
  isOpened,
  treeElement,
  startDragElement,
  setStartDragElement,
  children,
}) => {
  const [treeChunk, sort]: TreeChunkWSort = useTreeChunk(treeElement);
  const dispatch: (f: any) => AppDispatch = useDispatch();

  const dragStartHandler = (event: DragEvent<HTMLDivElement>) => {
    setStartDragElement(treeElement);
    event.currentTarget.classList.add('hide');
  };

  const dragLeaveHandler = (event: DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.background = 'inherit';
  };

  const dragEndHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.classList.remove('hide');
  };

  const dragOverHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.style.background = 'lightgray';
  };

  const dragDropHandler = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.style.background = 'inherit';
    if (!treeChunk.includes(startDragElement!)) {
      return;
    }

    // if sorting DESC changing it to ASC
    const treeChunkBySort =
      sort === 'T' ? [...treeChunk].reverse() : [...treeChunk];

    // changing order of the whole Tree chunk and changing startdrag and enddrag elements order
    const reorderedTreeChunk = treeChunkBySort.map(
      (childTreeElement: TreeElement, index: number) => {
        const trorder = index + 1;
        const reorderedChildTreeElement = { ...childTreeElement, trorder };
        if (reorderedChildTreeElement.trid === startDragElement!.trid)
          startDragElement = reorderedChildTreeElement;
        if (reorderedChildTreeElement.trid === treeElement.trid)
          treeElement = reorderedChildTreeElement;
        return reorderedChildTreeElement;
      }
    );

    // changing order value by dragged elements
    const updatedTreeChunk = reorderedTreeChunk.map(
      (childTreeElement: TreeElement) => {
        if (childTreeElement.trorder === startDragElement!.trorder!) {
          const trorder =
            startDragElement!.trorder! < treeElement.trorder!
              ? treeElement.trorder! + 1
              : treeElement.trorder! - 1;
          return { ...childTreeElement, trorder };
        } else if (childTreeElement.trorder! > treeElement!.trorder!) {
          const trorder = childTreeElement.trorder! + 1;
          return { ...childTreeElement, trorder };
        }
        return childTreeElement;
      }
    );

    // resorting Tree chunk
    updatedTreeChunk.sort((a, b) => a['trorder']! - b['trorder']!);

    dispatch(
      treeActions.setTree({
        trid: treeElement.trupid,
        treeChunk:
          sort === 'T'
            ? [...updatedTreeChunk].reverse()
            : [...updatedTreeChunk],
      })
    );

    await dispatch(updateTreeChunkOrder([...updatedTreeChunk]));
  };

  return (
    <Fragment>
      {treeChunk.length > 1 && !isOpened && (
        <DragArea
          draggable={true}
          onDragStart={dragStartHandler}
          onDragLeave={dragLeaveHandler}
          onDragEnd={dragEndHandler}
          onDragOver={dragOverHandler}
          onDrop={dragDropHandler}
        >
          {children}
        </DragArea>
      )}
      {(treeChunk.length <= 1 || isOpened) && <Fragment>{children}</Fragment>}
    </Fragment>
  );
};

const DragElement: React.FC<{
  isOpened: boolean | undefined;
  treeElement: TreeElement;
}> = ({ isOpened, treeElement }) => {
  const treeChunk = useTreeChunk(treeElement);

  return (
    <Fragment>
      {treeChunk.length > 1 && !isOpened && (
        <IconButton edge="start">
          <ICONS.DragIcon />
        </IconButton>
      )}
    </Fragment>
  );
};
export { DragnDrop, DragElement };
