import React, { Fragment, useState } from 'react';
// import { makeStyles, TextField } from '@material-ui/core';
import { TextField } from '@mui/material';
import useInput from '../../../../hooks/useInput';
import useSubmitModalForm from '../../../../hooks/use-submitmodalform';
import {
  validateUserName,
  validatePassword,
  validateEmail
} from '../../../../utils/validators';

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     marginTop: theme.spacing(8),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//   },
//   title: {
//     paddingTop: theme.spacing(2),
//     paddingBottom: theme.spacing(1),
//     paddingLeft: theme.spacing(1),
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'left',
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
//   },
//   avatar: {
//     margin: theme.spacing(1),
//     backgroundColor: theme.palette.secondary.main,
//   },
//   form: {
//     width: '100%', // Fix IE 11 issue.
//   },
//   submit: {
//     margin: theme.spacing(3, 0, 2),
//   },
//   errorBlock: {
//     textAlign: 'center',
//     color: theme.palette.error.main,
//   },
// }));

const UserForm: React.FC<{
  userAction: string;
  formSubmit: (data: any) => void;
  initialData?: any;
  isModalSaveButtonClicked: boolean;
  setIsModalSaveButtonClicked: (value: boolean) => void;
}> = ({
  userAction,
  formSubmit,
  initialData,
  isModalSaveButtonClicked,
  setIsModalSaveButtonClicked
}) => {
  // const classes = useStyles();
  const { form } = useSubmitModalForm(
    isModalSaveButtonClicked,
    setIsModalSaveButtonClicked
  );
  const {
    inputRef: emailInputRef,
    inputError: emailInputError,
    inputSubmit: emailInputSubmit
  } = useInput(validateEmail);

  const {
    inputRef: nameInputRef,
    inputError: nameInputError,
    inputSubmit: nameInputSubmit
  } = useInput(validateUserName);

  const {
    inputRef: passwordInputRef,
    inputError: passwordInputError,
    inputSubmit: passwordInputSubmit
  } = useInput(validatePassword);

  const {
    inputRef: passwordConfirmInputRef,
    inputError: passwordConfirmInputError,
    inputSubmit: passwordConfirmInputSubmit
  } = useInput(validatePassword);

  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const emailErrorText: string = 'Entered an invalid email';
  const nameErrorText: string = 'Entered name is invalid';
  const passwordErrorText: string =
    'Entered an invalid password (must be at leat 6 characters long)';
  const passwordMatchErrorText: string = 'Passwords do not match';

  const formSubmitionHandler = (event: React.FormEvent) => {
    event.preventDefault();
    const validateFormData = () => {
      const [enteredEmail, enteredEmailIsValid] = emailInputSubmit();
      const [enteredName, enteredNameIsValid] = nameInputSubmit();
      const [enteredPassword, enteredPasswordIsValid] = passwordInputSubmit();
      const [
        enteredConfirmPassword,
        enteredConfirmPasswordIsValid
      ] = passwordConfirmInputSubmit();
      const formIsValid =
        enteredEmailIsValid &&
        enteredNameIsValid &&
        enteredPasswordIsValid &&
        enteredConfirmPasswordIsValid &&
        enteredPassword === enteredConfirmPassword;

      if (!formIsValid) {
        if (enteredPassword !== enteredConfirmPassword)
          setPasswordMatchError(true);
        return null;
      }

      setPasswordMatchError(false);

      return {
        adlogin: enteredEmail,
        adname: enteredName,
        adpassword: enteredPassword
      };
    };

    const formData = validateFormData();

    if (!formData) {
      return;
    }

    if (userAction === 'update') formData.adlogin = initialData.adlogin;

    formSubmit(formData);

    // if (setIsModalClosed && setIsModalSaveButtonClicked) {
    //   setIsModalClosed(true);
    //   setIsModalSaveButtonClicked(false);
    // }
  };

  return (
    <Fragment>
      <form
        ref={form}
        // className={classes.form}
        onSubmit={formSubmitionHandler}
        noValidate
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          defaultValue={initialData && initialData.adlogin}
          inputRef={emailInputRef}
          error={emailInputError}
          helperText={emailInputError ? emailErrorText : ''}
          disabled={userAction !== 'add'}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          defaultValue={initialData && initialData.adname}
          inputRef={nameInputRef}
          error={nameInputError}
          helperText={nameInputError ? nameErrorText : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          inputRef={passwordInputRef}
          error={passwordInputError}
          helperText={passwordInputError ? passwordErrorText : ''}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="passwordConfirm"
          label="Password confirmation"
          type="password"
          id="passwordConfirm"
          autoComplete="current-password"
          inputRef={passwordConfirmInputRef}
          error={passwordConfirmInputError || passwordMatchError}
          helperText={
            passwordConfirmInputError
              ? passwordErrorText
              : passwordMatchError
              ? passwordMatchErrorText
              : ''
          }
        />
      </form>
    </Fragment>
  );
};

export default UserForm;
