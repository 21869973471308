import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../store/auth-slice';

import {
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  List as ListIcon,
  People as PeopleIcon,
  Construction as ConstructionIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';

const MAIN_ITEMS = [
  {
    name: 'Dashboard',
    link: 'dashboard',
    icon: DashboardIcon,
  },
  {
    name: 'Administrators',
    link: 'administrators',
    icon: PeopleIcon,
  },
  {
    name: 'Structure',
    link: 'structure',
    icon: ListIcon,
  },
  {
    name: 'Configuration',
    link: 'configuration',
    icon: ConstructionIcon,
  },
];

export const MainListItems: React.FC<{ urlParams: string; isOpened: boolean }> =
  ({ urlParams, isOpened }) => {
    const dispatch = useDispatch();
    const logoutHandler = () => {
      dispatch(authActions.logout());
    };
    return (
      <div>
        {MAIN_ITEMS.map((item, index) => {
          const IconComponent = item.icon;
          return (
            <ListItemButton
              key={index}
              component={Link}
              to={`/profile/${item.link}`}
              selected={urlParams === item.link}
            >
              <ListItemIcon>
                <IconComponent />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          );
        })}
        <Divider
          sx={{
            mt: '24px',
            mb: '24px',
          }}
        />
        {isOpened && (
          <Button
            color="primary"
            onClick={logoutHandler}
            variant="contained"
            startIcon={<LogoutIcon />}
            sx={{ ml: '16px' }}
          >
            Logout
          </Button>
        )}
        {!isOpened && (
          <ListItemButton onClick={logoutHandler}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
          </ListItemButton>
        )}
      </div>
    );
  };
