import * as Validators from '../utils/validators';
import { TreeFormElement } from './types';
import * as Func from '../utils/func';
import { v4 as uuid } from 'uuid';

const FormElementPage: TreeFormElement[] = [
  {
    id: uuid(),
    name: 'Date and time',
    code: 'trdate',
    defaultValue: null,
    errorText: 'Entered an invalid date',
    type: 'datepicker',
    validator: () => true,
    placement: 'tree',
    required: true,
    sx: { mt: '5px' },
  },
  {
    id: uuid(),
    name: 'Name',
    code: 'trname',
    defaultValue: null,
    errorText: 'Entered an invalid name',
    type: 'text',
    validator: Validators.validateName,
    placement: 'tree',
    required: true,
  },
  {
    id: uuid(),
    name: 'Code',
    code: 'trcode',
    defaultValue: null,
    errorText: 'Entered an invalid code',
    type: 'text',
    validator: Validators.validateCode,
    placement: 'tree',
    required: true,
    size: {
      xs: 12,
      md: 9,
      lg: 9,
    },
    additional: {
      type: 'button',
      name: 'Generate',
      linkedTo: () => FormElementPage[1].id,
      generateFn: Func.transliterate,
      size: {
        xs: 12,
        md: 3,
        lg: 3,
      },
    },
  },
  {
    id: uuid(),
    name: 'Default',
    code: 'trdefault',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Hidden',
    code: 'trhidden',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Empty',
    code: 'trempty',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: (value) => {
      return value === 'F' ? 'Sorting ASC' : 'Sorting DESC';
    },
    code: 'trsort',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
  {
    id: uuid(),
    name: 'Keywords',
    code: 'pawords',
    defaultValue: null,
    errorText: 'Entered an invalid keywords',
    type: 'text',
    validator: () => true,
    placement: 'data',
    required: false,
    rest: {
      multiline: true,
      maxRows: 4,
    },
  },
  {
    id: uuid(),
    name: 'Description',
    code: 'padesc',
    defaultValue: null,
    errorText: 'Entered an invalid description',
    type: 'text',
    validator: () => true,
    placement: 'data',
    required: false,
    rest: {
      multiline: true,
      maxRows: 4,
    },
  },
  {
    id: uuid(),
    name: 'Short text',
    code: 'pashort',
    defaultValue: null,
    errorText: 'Entered an invalid short text',
    type: 'text',
    validator: () => true,
    placement: 'data',
    required: false,
    rest: {
      multiline: true,
      // maxRows: 4,
    },
  },
  {
    id: uuid(),
    name: 'Full text',
    code: 'pafull',
    defaultValue: null,
    errorText: 'Entered an invalid full text',
    type: 'textEditor',
    validator: () => true,
    placement: 'data',
    required: false,
    rest: {
      multiline: true,
      // maxRows: 4,
    },
  },
];

export default FormElementPage;
