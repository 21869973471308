import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth-slice';
import userReducer from './user-slice';
import treeReducer from './tree-slice';
import loadingReducer from './loading-slice';
import errorReducer from './error-slice';
import noticeReducer from './notice-slice';
import setupReducer from './setup-slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    tree: treeReducer,
    setup: setupReducer,
    loading: loadingReducer,
    notice: noticeReducer,
    error: errorReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
