import { Fragment, useState } from 'react';
import { Grid, Paper, Button } from '@mui/material';
import { PersonAdd as PersonAddIcon } from '@mui/icons-material';
import Title from '../Title';
import UserList from './UserList';

const ProfileAdministrators: React.FC = () => {
  const [isUserAdd, setIsUserAdd] = useState(false);

  const addUserHandler = () => {
    setIsUserAdd(!isUserAdd);
  };

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Title>Administrators</Title>
            <div>
              <Button
                onClick={addUserHandler}
                variant="contained"
                color="secondary"
                startIcon={<PersonAddIcon />}
                sx={{ ml: '16px' }}
              >
                Add User
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              pb: 4,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <UserList
              onAddUser={isUserAdd}
              toggleAddUserDialog={addUserHandler}
            />
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProfileAdministrators;
