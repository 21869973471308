import React, { Fragment, useState, useEffect } from 'react';
// import { styled } from '@mui/material/styles';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  CircularProgress,
} from '@mui/material';
import ElementOptions from '../../../../UI/Menu';
import { DragnDrop, DragElement } from '../../../../UI/DragnDropTreeItem';

// configs
import {
  ICONS,
  TRCLASSES,
  TREE_ELEMENT_OPTIONS,
} from '../../../../../config/config';
// types
import { TreeElement } from '../../../../../config/types';

const TreeItemIcon: React.FC<{
  icon: string | number;
  onRefresh?: () => void;
}> = ({ icon, onRefresh }) => {
  return (
    <Fragment>
      {icon && icon === 'open' && <ICONS.OpenIcon />}
      {icon && icon === 'close' && <ICONS.CloseIcon />}
      {icon && icon === 'single' && <ICONS.SingleIcon />}
      {icon && icon === 'delete' && <ICONS.DeleteIcon />}
      {icon && icon === 'more' && <ICONS.MoreIcon />}
      {icon && icon === 'default' && (
        <ICONS.DefaultIcon sx={{ color: 'secondary.light' }} />
      )}
      {icon && icon === 'hidden' && (
        <ICONS.HiddenIcon sx={{ color: 'primary.light', opacity: '0.35' }} />
      )}
      {icon && (icon === 'folder' || icon === TRCLASSES['section']) && (
        <ICONS.SectionIcon sx={{ color: 'warning.main' }} />
      )}
      {icon && icon === TRCLASSES['page'] && (
        <ICONS.PageIcon sx={{ color: 'primary.light' }} />
      )}
      {icon && icon === TRCLASSES['shop'] && (
        <ICONS.ShopIcon sx={{ color: 'primary.main' }} />
      )}
      {icon && icon === TRCLASSES['group'] && (
        <ICONS.ProductGroupIcon sx={{ color: 'warning.main' }} />
      )}
      {icon && icon === TRCLASSES['product'] && (
        <ICONS.ProductIcon sx={{ color: 'primary.light' }} />
      )}
      {icon && icon === TRCLASSES['image'] && (
        <ICONS.ImageIcon sx={{ color: 'success.light' }} />
      )}
      {icon && icon === TRCLASSES['file'] && (
        <ICONS.FileIcon sx={{ color: 'error.light' }} />
      )}
      {icon && icon === 'refresh' && (
        <ICONS.RefreshIcon onClick={onRefresh ? onRefresh : undefined} />
      )}
    </Fragment>
  );
};

const TreeItem: React.FC<{
  treeElement: TreeElement;
  isSingle: boolean;
  isOpened?: boolean;
  itemIcon: string;
  title: string;
  secondaryText?: string;
  isLoadingLocal: boolean;
  onOpen: () => void;
  onRefresh: () => void;
  toggleTreeElementDialog: (
    treeElement: TreeElement,
    action: string,
    treeElementType: number
  ) => void;
  startDragElement: TreeElement | null;
  setStartDragElement: (treeElement: TreeElement | null) => void;
}> = ({
  treeElement,
  isSingle,
  isOpened,
  itemIcon,
  title,
  secondaryText,
  isLoadingLocal,
  onOpen,
  onRefresh,
  toggleTreeElementDialog,
  startDragElement,
  setStartDragElement,
}) => {
  const [isMeClicked, setIsMeClicked] = useState(0);

  const refreshClickOrOpenHandler = (type: string) => {
    setIsMeClicked(
      type === 'open' || type === 'refresh' ? treeElement.trid : 0
    );
    return type === 'open' || type === 'close' ? onOpen() : onRefresh();
  };

  useEffect(() => {
    if (!isLoadingLocal) {
      setIsMeClicked(0);
    }
  }, [isLoadingLocal]);

  return (
    <Fragment>
      <DragnDrop
        isOpened={isOpened}
        treeElement={treeElement}
        startDragElement={startDragElement}
        setStartDragElement={setStartDragElement}
      >
        <ListItem
          sx={{
            maxWidth: 'auto',
            pr: 0,
            borderLeft: !isSingle && isOpened ? 5 : 'none',
            borderColor: !isSingle && isOpened ? 'secondary.light' : 'none',
          }}
          secondaryAction={
            <Fragment>
              <IconButton edge="start" aria-label="delete" sx={{ mr: '10px;' }}>
                {(!isLoadingLocal || isMeClicked !== treeElement.trid) &&
                  !isSingle && (
                    <TreeItemIcon
                      icon="refresh"
                      onRefresh={() => refreshClickOrOpenHandler('refresh')}
                    />
                  )}
                {isLoadingLocal && isMeClicked === treeElement.trid && (
                  <CircularProgress disableShrink color="info" size="16px" />
                )}
              </IconButton>

              <ElementOptions
                treeElement={treeElement}
                menu={TREE_ELEMENT_OPTIONS}
                toggleTreeElementDialog={toggleTreeElementDialog}
              >
                <IconButton edge="start" aria-label="more" sx={{ mr: '10px;' }}>
                  <TreeItemIcon icon="more" />
                </IconButton>
              </ElementOptions>
              <DragElement isOpened={isOpened} treeElement={treeElement} />
            </Fragment>
          }
        >
          {!isSingle && (
            <ListItemIcon
              sx={{
                minWidth: 'auto',
                cursor: 'pointer',
                ml: isOpened ? '-5px' : '0',
              }}
              onClick={() =>
                refreshClickOrOpenHandler(!isOpened ? 'open' : 'close')
              }
            >
              <TreeItemIcon icon={!isOpened ? 'open' : 'close'} />
            </ListItemIcon>
          )}
          {isSingle && (
            <ListItemIcon sx={{ minWidth: 'auto' }}>
              <TreeItemIcon icon="single" />
            </ListItemIcon>
          )}
          <ListItemButton
            component="div"
            onClick={() =>
              toggleTreeElementDialog(treeElement, 'edit', treeElement.trclass)
            }
          >
            <ListItemIcon
              sx={{
                minWidth: 'auto',
                mr: '10px',
              }}
            >
              <TreeItemIcon icon={itemIcon} />
            </ListItemIcon>
            {treeElement.trdefault === 'T' && (
              <ListItemIcon
                sx={{
                  minWidth: 'auto',
                  mr: '10px',
                }}
              >
                <TreeItemIcon icon="default" />
              </ListItemIcon>
            )}
            {treeElement.trhidden === 'T' && (
              <ListItemIcon
                sx={{
                  minWidth: 'auto',
                  mr: '10px',
                }}
              >
                <TreeItemIcon icon="hidden" />
              </ListItemIcon>
            )}
            <ListItemText
              primary={title}
              secondary={secondaryText ? secondaryText : null}
            />
          </ListItemButton>
        </ListItem>
      </DragnDrop>
    </Fragment>
  );
};

export default TreeItem;
