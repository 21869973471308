import Typography from '@mui/material/Typography';

const Copyright = (props: any) => {
  return (
    <Typography variant="body2" color="textSecondary" align="center" {...props}>
      {'Copyright © '}
      <span>elf</span> {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Copyright;
