import { Fragment, useEffect } from 'react';
import { Grid, Paper, Button } from '@mui/material';
import Title from '../Title';
import ConfigItem from './ConfigItem';
import { RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../config/types';
import { setupActions } from '../../../../store/setup-slice';
import { getSetup, updateSetup } from '../../../../store/setup-actions';
import { v4 as uuid } from 'uuid';
import { ICONS } from '../../../../config/config';

// const defaultConfig: SetupElement[] = [];

const ProfileConfiguration: React.FC = () => {
  const dispatch: (f: any) => AppDispatch = useDispatch();
  const config = useSelector((state: RootState) => state.setup.setup);
  let validators: any[] = [];
  const addConfigFieldHandler = () => {
    dispatch(
      setupActions.set({
        setup: config.concat({
          stid: uuid(),
          stcode: '',
          stname: '',
          stvalue: '',
        }),
      })
    );
  };

  const addValidators = (...args: any) => {
    validators = [...validators, ...args];
  };

  const saveConfigFieldHandler = async () => {
    if (config.length === 0) return;
    let isSetupValid = true;
    for (const validator of validators) {
      const [_1, isValid] = validator();
      if (!isValid) isSetupValid = false;
    }
    if (isSetupValid) await dispatch(updateSetup([...config]));
  };

  useEffect(() => {
    const setupGet = async () => await dispatch(getSetup());
    setupGet();
  }, [dispatch]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Title>Configuration</Title>
            <div>
              <Button
                onClick={addConfigFieldHandler}
                variant="contained"
                color="secondary"
                startIcon={<ICONS.AddIcon />}
                sx={{ ml: '16px' }}
              >
                Add Field
              </Button>
              <Button
                onClick={saveConfigFieldHandler}
                variant="contained"
                color="success"
                startIcon={<ICONS.SaveIcon />}
                sx={{ ml: '16px' }}
                disabled={config.length === 0}
              >
                Save configuration
              </Button>
            </div>
          </Paper>
        </Grid>
        {config.map((configElement) => {
          return (
            <ConfigItem
              key={configElement.stid}
              configElement={configElement}
              config={config}
              addValidators={addValidators}
            />
          );
        })}
      </Grid>
    </Fragment>
  );
};

export default ProfileConfiguration;
