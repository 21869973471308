import { useState, useEffect, forwardRef } from 'react';
import { errorActions } from '../../store/error-slice';
import { noticeActions } from '../../store/notice-slice';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// severity: success, info, warning, error

const Notification: React.FC<{ severity: any; children?: React.ReactNode }> = ({
  severity,
  children,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(true);
  }, []);

  const [open, setOpen] = useState(false);

  const handleClose = (
    _event?: React.SyntheticEvent | any,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    dispatch(noticeActions.clearNotice());
    dispatch(errorActions.clearError());
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {children}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Notification;
