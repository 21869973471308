import * as Validators from '../utils/validators';
import { TreeFormElement } from './types';
import * as Func from '../utils/func';
import { v4 as uuid } from 'uuid';

const FormElementShop: TreeFormElement[] = [
  {
    id: uuid(),
    name: 'Name',
    code: 'trname',
    defaultValue: null,
    errorText: 'Entered an invalid name',
    type: 'text',
    validator: Validators.validateName,
    placement: 'tree',
    required: true,
  },
  {
    id: uuid(),
    name: 'Code',
    code: 'trcode',
    defaultValue: null,
    errorText: 'Entered an invalid code',
    type: 'text',
    validator: Validators.validateCode,
    placement: 'tree',
    required: true,
    size: {
      xs: 12,
      md: 9,
      lg: 9,
    },
    additional: {
      type: 'button',
      name: 'Generate',
      linkedTo: () => FormElementShop[0].id,
      generateFn: Func.transliterate,
      size: {
        xs: 12,
        md: 3,
        lg: 3,
      },
    },
  },
  {
    id: uuid(),
    name: 'Hidden',
    code: 'trhidden',
    defaultValue: 'F',
    type: 'checkbox',
    placement: 'tree',
    size: {
      xs: 12,
      md: 3,
      lg: 3,
    },
  },
];

export default FormElementShop;
