import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SetupElement } from '../config/types';

const initialsetupState: { setup: SetupElement[] } = {
  setup: [],
};

const setupSlice = createSlice({
  name: 'setup',
  initialState: initialsetupState,
  reducers: {
    set(
      state,
      action: PayloadAction<{
        setup: SetupElement[];
      }>
    ) {
      state.setup = action.payload.setup;
    },
    clear(state) {
      state.setup = [];
    },
  },
});

export const setupActions = setupSlice.actions;
export default setupSlice.reducer;
