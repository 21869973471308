import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { TreeElement, TreeMeta } from '../config/types';

export type TreeChunkWSort = [TreeElement[], 'T' | 'F'];

const getTreeChunk = (
  treeElement: TreeElement,
  meta: TreeMeta,
  tree: TreeElement[]
) => {
  const trid = treeElement.trupid !== 0 ? treeElement.trupid : treeElement.trid;
  const data = meta[trid].reduce(
    (a: any, b: any) => {
      return treeElement.trupid !== 0
        ? [a[0][b].children, !a[0][b].trsort ? 'F' : a[0][b].trsort]
        : [a[0], 'F'];
    },
    [tree, 'F']
  );

  return data as TreeChunkWSort;
};

const useTreeChunk = (treeElement: TreeElement): TreeChunkWSort => {
  const [data, setData] = useState<TreeChunkWSort>([[], 'F']);
  const tree = useSelector((state: RootState) => state.tree.tree);
  const meta = useSelector((state: RootState) => state.tree.meta);

  const treeChunkHandler = useCallback(() => {
    const data = getTreeChunk(treeElement, meta, tree);
    setData(data);
  }, [meta, tree, treeElement]);

  useEffect(() => {
    treeChunkHandler();
  }, [treeChunkHandler]);

  return data;
};

export default useTreeChunk;
