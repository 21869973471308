import { createSlice } from '@reduxjs/toolkit';

interface User {
  adlogin: string;
  adname: string;
  adpassword?: string;
  action?: string; // this prop is for user operations: add, update, delete
}

const initialuserState = {
  users: [] as User[],
};

const userSlice = createSlice({
  name: 'users',
  initialState: initialuserState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload;
    },
    clearUsers(state) {
      state.users = [];
    },
    insertUser(state, action) {
      state.users.push({
        adlogin: action.payload.adlogin,
        adname: action.payload.adname,
      });
    },
    updateUser(state, action) {
      const newUserList = state.users.map((user) =>
        user.adlogin === action.payload.adlogin
          ? { adlogin: action.payload.adlogin, adname: action.payload.adname }
          : user
      );
      state.users = newUserList;
    },
    deleteUser(state, action) {
      const newUserList = state.users.filter(
        (user) => user.adlogin !== action.payload.adlogin
      );
      state.users = newUserList;
    },
  },
});

export type { User };
export const userActions = userSlice.actions;
export default userSlice.reducer;
