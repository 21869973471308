import React, { useState } from 'react';
import MuiAvatar from '@mui/material/Avatar';
import MuiButton from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import MuiCard from '@mui/material/Card';
import MuiCardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import useInput from '../../../hooks/useInput';
import { useDispatch } from 'react-redux';
import { sendLoginRequest, Credentials } from '../../../store/auth-actions';
import { loadingActions } from '../../../store/loading-slice';
import { useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../../../store/index';
import LoadingLocal from '../../UI/LoadingLocal';
import Copyright from '../../UI/Copyright';
import { validateEmail, validatePassword } from '../../../utils/validators';

const Card = styled(
  MuiCard,
  {}
)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const CardActionArea = styled(MuiCardActionArea)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'left',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));
const Avatar = styled(MuiAvatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const Form = styled('form')(() => ({
  width: '100%', // Fix IE 11 issue.
}));

const Button = styled(MuiButton)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const ErrorParagraph = styled('p')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.error.main,
}));

const Login: React.FC = () => {
  const dispatch: (f: any) => AppDispatch = useDispatch();
  const isLoadingLocal = useSelector(
    (state: RootState) => state.loading.isLoadingLocal
  );
  const error = useSelector((state: RootState) => state.error.error);

  const {
    inputRef: emailInputRef,
    inputError: emailInputError,
    inputSubmit: emailInputSubmit,
  } = useInput(validateEmail);

  const {
    inputRef: passwordInputRef,
    inputError: passwordInputError,
    inputSubmit: passwordInputSubmit,
  } = useInput(validatePassword);

  const [checkboxState, setCheckBoxState] = useState(false);

  const emailErrorText: string = 'Entered an invalid email';
  const passwordErrorText: string =
    'Entered an invalid password (must be at leat 6 characters long)';

  const formSubmitionHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    const [enteredEmail, enteredEmailIsValid] = emailInputSubmit();
    const [enteredPassword, enteredPasswordIsValid] = passwordInputSubmit();
    const formIsValid = enteredEmailIsValid && enteredPasswordIsValid;

    if (!formIsValid) return;

    const credentials: Credentials = {
      email: enteredEmail,
      password: enteredPassword,
      remember: checkboxState,
    };

    dispatch(loadingActions.isLoadingLocal());
    await dispatch(sendLoginRequest(credentials));
    dispatch(loadingActions.isNotLoadingLocal());
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card>
        <CardActionArea>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ELF CMS ADMIN
          </Typography>
        </CardActionArea>
        <CardContent>
          <Form onSubmit={formSubmitionHandler} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              inputRef={emailInputRef}
              error={emailInputError}
              helperText={emailInputError ? emailErrorText : ''}
              disabled={isLoadingLocal}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              inputRef={passwordInputRef}
              error={passwordInputError}
              helperText={passwordInputError ? passwordErrorText : ''}
              disabled={isLoadingLocal}
            />
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={checkboxState}
                  onChange={() => setCheckBoxState(!checkboxState)}
                />
              }
              label="Remember me"
              disabled={isLoadingLocal}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoadingLocal}
              size="large"
            >
              {!isLoadingLocal && <span>Log In</span>}
              {isLoadingLocal && <LoadingLocal size="25" />}
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
              {error && (
                <Grid item xs={12}>
                  <ErrorParagraph>{error}</ErrorParagraph>
                </Grid>
              )}
            </Grid>
          </Form>
        </CardContent>
      </Card>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
