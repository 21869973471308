import API, { SERVER_HTTP } from '../utils/API';
import { treeActions } from './tree-slice';
import { loadingActions } from './loading-slice';
import { errorActions } from './error-slice';
import { noticeActions } from './notice-slice';
import { to } from '../utils/func';
import { TreeElement } from '../config/types';
// import { result } from 'lodash';

/**
 * Tree chunk from server
 * @param currentTreeElement
 * @returns
 */
export const getTreeChunk = (currentTreeElement: TreeElement | null) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.post('/admin/tree', {
        trid: currentTreeElement ? currentTreeElement.trid : 0,
      })
        .then((response) => {
          return JSON.parse(response.data.treeChunk);
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      dispatch(loadingActions.isLoadingLocal());
      let treeChunk = await sendRequest();
      dispatch(
        treeActions.setTree({
          trid: currentTreeElement ? currentTreeElement.trid : 0,
          treeChunk,
        })
      );
      dispatch(loadingActions.isNotLoadingLocal());

      return treeChunk;
    } catch (error: any) {
      dispatch(loadingActions.isNotLoadingLocal());
      dispatch(errorActions.setError(error.message));
    }
  };
};

export const getTreeElement = (trid: number) => {
  return async (dispatch: any) => {
    let data: TreeElement;
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async (): Promise<TreeElement> => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.get('/admin/tree/' + trid)
        .then((response) => {
          return response.data.treeElement;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      dispatch(loadingActions.isLoading());
      data = await sendRequest();
      dispatch(loadingActions.isNotLoading());

      return data;
    } catch (error: any) {
      dispatch(loadingActions.isNotLoading());
      dispatch(errorActions.setError(error.message));
    }
  };
};

export const insertTreeElement = (
  treeElement: TreeElement,
  formData: FormData | null = null
) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.post('/admin/tree/add', { treeElement })
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    dispatch(loadingActions.isLoading());
    const [error, response] = await to(sendRequest());
    if (error) {
      dispatch(loadingActions.isNotLoading());
      dispatch(errorActions.setError(error.message));
      return;
    }
    dispatch(
      treeActions.setTree({
        trid: treeElement.parent!.trid,
        treeChunk: response.result.treeChunk,
      })
    );
    if (formData) {
      const [error, _1] = await to(
        dispatch(uploadFile(formData, response.result.trid))
      );
      if (error) {
        dispatch(loadingActions.isNotLoading());
        dispatch(errorActions.setError(error.message));
        return;
      }
    }
    dispatch(loadingActions.isNotLoading());
    dispatch(noticeActions.setNotice(response.message || 'Element added'));

    return response;
  };
};

export const updateTreeElement = (
  treeElement: TreeElement,
  formData: FormData | null = null
) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.put('/admin/tree/update', { treeElement })
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    dispatch(loadingActions.isLoading());
    const [error, data] = await to(sendRequest());
    if (error) {
      dispatch(loadingActions.isNotLoading());
      dispatch(errorActions.setError(error.message));
      return;
    }
    dispatch(treeActions.updateTree({ treeElement }));
    if (formData) {
      console.log('uploading...');
      const [error, _1] = await to(
        dispatch(uploadFile(formData, treeElement.trid))
      );
      if (error) {
        dispatch(loadingActions.isNotLoading());
        dispatch(errorActions.setError(error.message));
        return;
      }
    }
    dispatch(loadingActions.isNotLoading());
    dispatch(noticeActions.setNotice(data.message || 'Element updated'));
  };
};

export const updateTreeChunkOrder = (treeChunk: TreeElement[]) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.post('/admin/tree/chunk/order/update', { treeChunk })
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    dispatch(loadingActions.isLoading());
    const [error, _1] = await to(sendRequest());

    if (error) {
      dispatch(errorActions.setError(error.message));
    }

    dispatch(loadingActions.isNotLoading());
    return;
  };
};

export const deleteTreeElement = (treeElement: TreeElement) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token) dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.post('/admin/tree/delete', { treeElement })
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      dispatch(loadingActions.isLoading());
      const response = await sendRequest();

      dispatch(
        treeActions.setTree({
          trid: treeElement.trupid,
          treeChunk: response.result.treeChunk,
        })
      );
      dispatch(loadingActions.isNotLoading());
      dispatch(noticeActions.setNotice(response.message || 'Element deleted'));

      return response;
    } catch (error: any) {
      dispatch(loadingActions.isNotLoading());
      dispatch(errorActions.setError(error.message));
    }
  };
};

export const uploadFile = (formData: any, trid: number) => {
  return async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      const token: string | null = localStorage.getItem('token');
      if (!token) dispatch(errorActions.setError('Authorization required'));
      formData.append('trid', trid.toString());

      const sendRequest = async () => {
        SERVER_HTTP.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`;
        return SERVER_HTTP.post('api/admin/tree/upload', formData);
      };

      const [error, response] = await to(sendRequest());
      if (error) return reject(error);
      return resolve(response.data);
    });
  };
};
