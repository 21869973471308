import API from '../utils/API';
import { authActions } from './auth-slice';
import { errorActions } from './error-slice';

export interface Credentials {
  email: string;
  password: string;
  remember: boolean;
}

interface LoginResponse {
  ok?: boolean;
  message?: string;
  token: string;
  expires: string;
  refreshToken?: string;
}

export const localStorageSetAuthData = (authResponse: LoginResponse) => {
  localStorage.setItem('token', authResponse.token);
  localStorage.setItem('expires', authResponse.expires);
  localStorage.setItem('refreshToken', authResponse.refreshToken!);
};

export const localStorageGetAuthData = () => {
  return [
    localStorage.getItem('token') as string,
    localStorage.getItem('expires') as string,
    localStorage.getItem('refreshToken') as string,
  ];
};

export const localStorageRemoveAuthData = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expires');
  localStorage.removeItem('refreshToken');
};

export const sendLoginRequest = (credentials: Credentials) => {
  return async (dispatch: any) => {
    let authResponse: LoginResponse;

    const sendRequest = async () => {
      return API.post('login', credentials)
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      authResponse = await sendRequest();
      dispatch(errorActions.clearError());
    } catch (error: any) {
      dispatch(errorActions.setError(error.message));
      return;
    }

    // localStorage.setItem('token', authResponse.token);
    dispatch(
      authActions.login({
        token: authResponse.token,
        expires: authResponse.expires,
        refreshToken: authResponse.refreshToken!,
      })
    );
  };
};

export const sendAuthRequest = (token: string) => {
  return async (dispatch: any) => {
    const sendRequest = async () => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.get('auth')
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      await sendRequest();
      dispatch(errorActions.clearError());
      dispatch(authActions.auth());
    } catch (error: any) {
      // localStorage.removeItem('token');
      localStorageRemoveAuthData();
      dispatch(authActions.logout());
      dispatch(errorActions.setError(error.message));
      return;
    }
  };
};

export const sendRefreshRequest = (token: string, refreshToken: string) => {
  return async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      dispatch(errorActions.clearError());
      const sendRequest = async () => {
        API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return API.post('refresh', { refreshToken })
          .then((response) => {
            return response.data;
          })
          .catch((error: any) => {
            throw new Error(error.response.data.message || error.message);
          });
      };

      try {
        const refreshResponse = await sendRequest();
        dispatch(
          authActions.login({
            token: refreshResponse.token,
            expires: refreshResponse.expires,
            refreshToken: refreshResponse.refreshToken!,
          })
        );
        return resolve(true);
      } catch (error: any) {
        localStorageRemoveAuthData();
        dispatch(authActions.logout());
        return reject(error);
      }
    });
  };
};
