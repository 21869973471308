import { createSlice } from '@reduxjs/toolkit';

interface CustomError {
  error: string | null;
}

const initialErrorState: CustomError = {
  error: null,
};

const errorSlice = createSlice({
  name: 'error',
  initialState: initialErrorState,
  reducers: {
    setError(state, action) {
      state.error = action.payload.toUpperCase();
    },
    clearError(state) {
      state.error = null;
    },
  },
});

export type { CustomError };
export const errorActions = errorSlice.actions;
export default errorSlice.reducer;
