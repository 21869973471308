import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { TreeFormElement } from '../../config/types';
import useInput from '../../hooks/useInput';

type Props = {
  formElement: TreeFormElement;
  defaultValue: string | null;
  state: (
    formElement: TreeFormElement,
    submitState: any,
    inputRef: React.Ref<HTMLInputElement>,
    setStateFn: any,
    touched?: boolean
  ) => void;
  [args: string]: any;
};

const InputTextField: React.FC<Props> = ({
  formElement,
  defaultValue,
  state,
  ...rest
}) => {
  const { inputRef, inputError, inputSubmit } = useInput(
    formElement.validator!
  );
  const [inputValue, setInputValue] = useState(
    defaultValue ? defaultValue : ''
  );

  const valueSizeHandler = () => {
    let size = 4;
    if (inputValue.length > 100) size = 15;
    if (inputValue.length > 1000) size = 25;
    return size;
  };

  useEffect(() => {
    state(
      formElement,
      inputSubmit,
      inputRef,
      setInputValue,
      defaultValue ? true : false
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      id={formElement.code}
      label={
        typeof formElement.name === 'function'
          ? formElement.name(null)
          : formElement.name
      }
      name={formElement.code}
      value={inputValue}
      onChange={() => {
        setInputValue(inputRef.current!.value);
        state(formElement, inputSubmit, inputRef, setInputValue);
      }}
      // defaultValue={defaultValue && defaultValue}
      inputRef={inputRef}
      error={inputError}
      helperText={inputError ? formElement.errorText : ''}
      maxRows={valueSizeHandler()}
      {...rest}
    />
  );
};

export default InputTextField;
