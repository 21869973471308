import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    isLoading: false,
    isLoadingLocal: false,
  },
  reducers: {
    isLoading(state) {
      state.isLoading = true;
    },
    isNotLoading(state) {
      state.isLoading = false;
    },
    isLoadingLocal(state) {
      state.isLoadingLocal = true;
    },
    isNotLoadingLocal(state) {
      state.isLoadingLocal = false;
    },
  },
});

export const loadingActions = loadingSlice.actions;

export default loadingSlice.reducer;
