import { useEffect, useState } from 'react';
import { Grid, Paper, Button, TextField } from '@mui/material';
import { ICONS } from '../../../../config/config';
import { SetupElement } from '../../../../config/types';
import useInput from '../../../../hooks/useInput';
import { validateName, validateCode } from '../../../../utils/validators';
import { transliterate } from '../../../../utils/func';
import { setupActions } from '../../../../store/setup-slice';
import { deleteSetupElement } from '../../../../store/setup-actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../config/types';
import Confirm from '../../../UI/Confirm';

type Props = {
  configElement: SetupElement;
  config: SetupElement[];
  addValidators: (...args: any) => void;
};

const ConfigItem: React.FC<Props> = ({
  configElement,
  config,
  addValidators,
}) => {
  const dispatch: (f: any) => AppDispatch = useDispatch();
  const [confirmState, setConfirmState] = useState(false);

  const {
    inputRef: stcodeInputRef,
    inputError: stcodeInputError,
    inputSubmit: stcodeInputSubmit,
  } = useInput(validateCode);

  const {
    inputRef: stnameInputRef,
    inputError: stnameInputError,
    inputSubmit: stnameInputSubmit,
  } = useInput(validateName);

  const {
    inputRef: stvalueInputRef,
    inputError: stvalueInputError,
    inputSubmit: stvalueInputSubmit,
  } = useInput(() => true);

  addValidators(stcodeInputSubmit, stnameInputSubmit, stvalueInputSubmit);

  const errorText = 'wrong field format';

  const [inputCodeValue, setInputCodeValue] = useState(
    configElement.stcode ? configElement.stcode : ''
  );

  const [inputNameValue, setInputNameValue] = useState(
    configElement.stname ?? ''
  );

  const [inputValueValue, setInputValueValue] = useState(
    configElement.stvalue ?? ''
  );

  const generateCodeHandler = () => {
    setInputCodeValue(transliterate(stnameInputRef.current!.value));
    stcodeInputRef.current!.value = transliterate(
      stnameInputRef.current!.value
    );
    updateConfigHandler();
  };

  const toggleConfirm = () => {
    setConfirmState(!confirmState);
  };

  const deleteConfigElementHandler = async (confirm: boolean) => {
    if (!confirm) return;
    const updatedConfig = [...config];
    const index = updatedConfig.indexOf(configElement);
    if (index !== -1) updatedConfig.splice(index, 1);
    await dispatch(deleteSetupElement(configElement.stid));
    dispatch(setupActions.set({ setup: updatedConfig }));
  };

  const updateConfigHandler = () => {
    const updatedConfig = [...config];
    const index = updatedConfig.indexOf(configElement);
    if (index === -1) return;
    updatedConfig[index] = {
      stid: configElement.stid,
      stcode: stcodeInputRef.current!.value,
      stname: stnameInputRef.current!.value,
      stvalue: stvalueInputRef.current!.value,
    };
    dispatch(setupActions.set({ setup: updatedConfig }));
  };

  const valueSizeHandler = () => {
    let size = 4;
    if (inputValueValue.length > 100) size = 15;
    if (inputValueValue.length > 1000) size = 25;
    return size;
  };

  useEffect(() => {
    if (stcodeInputRef.current!.value.length > 0) stcodeInputSubmit();
    if (stnameInputRef.current!.value.length > 0) stnameInputSubmit();
  }, [stcodeInputRef, stnameInputRef, stcodeInputSubmit, stnameInputSubmit]);

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper
        sx={{
          p: 2,
          pb: 4,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="stcode"
              label="Code"
              name="Code"
              sx={{ mr: '15px' }}
              value={inputCodeValue}
              inputRef={stcodeInputRef}
              error={stcodeInputError}
              helperText={stcodeInputError ? errorText : ''}
              onChange={() => {
                setInputCodeValue(stcodeInputRef.current!.value);
                updateConfigHandler();
              }}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="stname"
              label="Name"
              name="Name"
              value={inputNameValue}
              inputRef={stnameInputRef}
              error={stnameInputError}
              helperText={stnameInputError ? errorText : ''}
              onChange={() => {
                setInputNameValue(stnameInputRef.current!.value);
                updateConfigHandler();
              }}
              required
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={generateCodeHandler}
              variant="contained"
              color="primary"
              sx={{ mr: '24px' }}
            >
              Generate code
            </Button>
            <Button
              onClick={toggleConfirm}
              startIcon={<ICONS.DeleteIcon />}
              variant="contained"
              color="secondary"
            >
              Delete
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="stvalue"
              label="Value"
              name="Value"
              multiline={true}
              maxRows={valueSizeHandler()}
              value={inputValueValue}
              inputRef={stvalueInputRef}
              error={stvalueInputError}
              helperText={stvalueInputError ? errorText : ''}
              onChange={() => {
                setInputValueValue(stvalueInputRef.current!.value);
                updateConfigHandler();
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Confirm
        state={confirmState}
        toggleConfirmState={toggleConfirm}
        confirm={deleteConfigElementHandler}
        title="Confirmation"
        message="Delete config element?"
      />
    </Grid>
  );
};

export default ConfigItem;
