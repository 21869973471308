import { TreeElement, TreeFormElement } from './types';
import FormElementSection from './FormElementSection';
import FormElementPage from './FormElementPage';
import FormElementShop from './FormElementShop';
import FormElementGroup from './FormElementGroup';
import FormElementProduct from './FormElementProduct';
import FormElementFile from './FormElementFile';
import FormElementImage from './FormElementImage';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Folder as SectionIcon,
  InsertDriveFileOutlined as PageIcon,
  ShoppingCart as ShopIcon,
  ImageOutlined as ImageIcon,
  AttachFileOutlined as FileIcon,
  ChevronRightOutlined as OpenIcon,
  ExpandMoreOutlined as CloseIcon,
  RoundedCornerOutlined as SingleIcon,
  RefreshOutlined as RefreshIcon,
  MoreVertOutlined as MoreIcon,
  ShoppingBag as ProductGroupIcon,
  ShoppingBagOutlined as ProductIcon,
  DragIndicator as DragIcon,
  Check as DefaultIcon,
  VisibilityOff as HiddenIcon,
  Save as SaveIcon,
} from '@mui/icons-material';

const ICONS = {
  AddIcon,
  EditIcon,
  DeleteIcon,
  SectionIcon,
  FolderIcon: SectionIcon,
  PageIcon,
  ShopIcon,
  ImageIcon,
  FileIcon,
  OpenIcon,
  CloseIcon,
  SingleIcon,
  RefreshIcon,
  MoreIcon,
  ProductGroupIcon,
  ProductIcon,
  DragIcon,
  DefaultIcon,
  HiddenIcon,
  SaveIcon,
};

const TRCLASSES = {
  section: 1,
  page: 2,
  template: 3,
  image: 4,
  file: 8,
  shop: 15,
  group: 16,
  product: 17,
};

const TRCLASS_NAMES: { [key: number]: string } = {
  1: 'section',
  2: 'page',
  3: 'template',
  4: 'image',
  8: 'file',
  15: 'shop',
  16: 'group',
  17: 'product',
};

const BLANK_TREE_ELEMENT: TreeElement = {
  trid: 0,
  trclass: 0,
  trupid: 0,
  trcode: '',
  trpath: '',
  trname: '',
  trownid: 0,
  trorder: 0,
  trdefault: 'F',
  trhidden: 'F',
  trsort: 'F',
  trdate: '',
  trtime: '',
  data: null,
};

const TREE_ELEMENT_OPTIONS = [
  {
    title: 'Add',
    icon: AddIcon,
    action: null,
    show: function (treeElement: TreeElement) {
      let showChildren = false;
      this.children.forEach((item) => {
        if (item.show && item.show(treeElement)) {
          showChildren = true;
          return;
        }
      });
      return showChildren;
    },
    children: [
      {
        title: 'Section',
        icon: SectionIcon,
        action: 'add',
        type: TRCLASSES['section'],
        show: (treeElement: TreeElement) =>
          treeElement.trclass === TRCLASSES['section'],
      },
      {
        title: 'Page',
        icon: PageIcon,
        action: 'add',
        type: TRCLASSES['page'],
        show: (treeElement: TreeElement) =>
          treeElement.trclass === TRCLASSES['section'] ||
          treeElement.trclass === TRCLASSES['page'],
      },
      {
        title: 'Product group',
        icon: ProductGroupIcon,
        action: 'add',
        type: TRCLASSES['group'],
        show: (treeElement: TreeElement) =>
          treeElement.trclass === TRCLASSES['shop'] ||
          treeElement.trclass === TRCLASSES['group'],
      },
      {
        title: 'Product',
        icon: ProductIcon,
        action: 'add',
        type: TRCLASSES['product'],
        show: (treeElement: TreeElement) =>
          treeElement.trclass === TRCLASSES['group'],
      },
      {
        title: 'Image',
        icon: ImageIcon,
        action: 'add',
        type: TRCLASSES['image'],
        show: (treeElement: TreeElement) =>
          treeElement.trclass === TRCLASSES['section'] ||
          treeElement.trclass === TRCLASSES['page'] ||
          treeElement.trclass === TRCLASSES['group'] ||
          treeElement.trclass === TRCLASSES['product'] ||
          treeElement.trclass === TRCLASSES['shop'],
      },
      {
        title: 'File',
        icon: FileIcon,
        action: 'add',
        type: TRCLASSES['file'],
        show: (treeElement: TreeElement) =>
          treeElement.trclass === TRCLASSES['section'] ||
          treeElement.trclass === TRCLASSES['page'] ||
          treeElement.trclass === TRCLASSES['group'] ||
          treeElement.trclass === TRCLASSES['product'] ||
          treeElement.trclass === TRCLASSES['shop'],
      },
    ],
  },
  {
    title: 'Edit',
    icon: EditIcon,
    action: 'edit',
  },
  {
    title: 'Delete',
    icon: DeleteIcon,
    action: 'delete',
  },
];

const TREE_ELEMENT_FORM: { [key: number]: TreeFormElement[] } = {
  1: FormElementSection,
  2: FormElementPage,
  4: FormElementImage,
  8: FormElementFile,
  15: FormElementShop,
  16: FormElementGroup,
  17: FormElementProduct,
};
const IMAGE_MIME = ['image/jpeg', 'image/png'];

export {
  ICONS,
  TRCLASSES,
  TRCLASS_NAMES,
  BLANK_TREE_ELEMENT,
  TREE_ELEMENT_OPTIONS,
  TREE_ELEMENT_FORM,
  IMAGE_MIME,
};
