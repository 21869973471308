import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '../../../../store/index';
import { userActions } from '../../../../store/user-slice';
import { loadingActions } from '../../../../store/loading-slice';
import type { User } from '../../../../store/user-slice';
import {
  getUserList,
  insertUser,
  updateUser,
  deleteUser,
} from '../../../../store/user-actions';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Link,
} from '@mui/material';
import Confirm from '../../../UI/Confirm';
import ModalDialog from '../../../UI/ModalDialog';
import UserForm from './UserForm';

const UserList: React.FC<{
  onAddUser: boolean;
  toggleAddUserDialog: () => void;
}> = ({ onAddUser, toggleAddUserDialog }) => {
  // const [userList, setUserList] = useState(MOCK_USERS);
  const dispatch: (f: any) => AppDispatch = useDispatch();
  const userList: User[] = useSelector((state: RootState) => state.users.users);

  const [confirmState, setConfirmState] = useState(false);
  const [dialogState, setDialogState] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [userAction, setUserAction] = useState<string>('');

  // edit user
  const formSubmitionHandler = (newUserData: any) => {
    updateUserHandler(true, newUserData);
  };

  const toggleDialog = () => {
    setDialogState(!dialogState);
  };

  // delete user
  const toggleConfirm = () => {
    setConfirmState(!confirmState);
  };

  const updateUserHandler = async (state: boolean, newUserData?: User) => {
    if (!state || !selectedUser) return;
    dispatch(loadingActions.isLoading());
    if (userAction === 'add' && newUserData) {
      try {
        await dispatch(insertUser(newUserData));
        dispatch(userActions.insertUser(newUserData));
      } catch {}
      toggleDialog();
    }
    if (userAction === 'update' && newUserData) {
      try {
        await dispatch(updateUser(newUserData));
        dispatch(userActions.updateUser(newUserData));
      } catch {}
      toggleDialog();
    }
    if (userAction === 'delete') {
      try {
        await dispatch(deleteUser(selectedUser.adlogin));
        dispatch(userActions.deleteUser({ adlogin: selectedUser.adlogin }));
      } catch {}
      toggleConfirm();
    }
    dispatch(loadingActions.isNotLoading());
  };

  const selectUserHandler = (adlogin: string, action: string) => {
    let user: User | undefined = userList.find(
      (user) => user.adlogin === adlogin
    );
    if (!user && action !== 'add') return;
    if (!user) user = { adlogin: '', adname: '', action: '' };
    setSelectedUser(user);
    setUserAction(action);
    if (action === 'add' || action === 'update') toggleDialog();
    if (action === 'delete') toggleConfirm();
    // console.log(confirmState);
  };

  // add user
  useEffect(() => {
    if (onAddUser) {
      selectUserHandler('', 'add');
      toggleAddUserDialog();
    }
    const getUsers = async () => {
      await dispatch(getUserList());
    };

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onAddUser, toggleAddUserDialog]);

  return (
    <Fragment>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Login</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList.map((row) => (
            <TableRow key={row.adlogin} hover>
              <TableCell>{row.adlogin}</TableCell>
              <TableCell>{row.adname}</TableCell>
              <TableCell>
                <Button
                  onClick={() => selectUserHandler(row.adlogin, 'update')}
                  variant="outlined"
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Link
                  href="#"
                  variant="button"
                  color="error"
                  underline="hover"
                  onClick={() => selectUserHandler(row.adlogin, 'delete')}
                >
                  {'Delete'}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Confirm
        state={confirmState}
        toggleConfirmState={toggleConfirm}
        confirm={updateUserHandler}
        title="Confirmation"
        message="Delete user?"
      />
      <ModalDialog
        state={dialogState}
        toggleDialogState={toggleDialog}
        title={
          userAction === 'update'
            ? `Edit user ${selectedUser && selectedUser.adlogin}`
            : `Add user`
        }
      >
        <UserForm
          userAction={userAction}
          initialData={selectedUser}
          formSubmit={(data: any) => formSubmitionHandler(data)}
          isModalSaveButtonClicked={false} // mocked values
          setIsModalSaveButtonClicked={() => null} // mocked values
        />
      </ModalDialog>
    </Fragment>
  );
};

export default UserList;
