import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import useInput from '../../hooks/useInput';
// import { dateFromString } from '../../utils/func';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TreeFormElement } from '../../config/types';

const DateTimePickerField: React.FC<{
  formElement: TreeFormElement;
  defaultValue: string | { date: string; time: string } | null;
  state: (
    formElement: TreeFormElement,
    submitState: any,
    inputRef: React.Ref<HTMLInputElement>,
    setStateFn: any,
    touched?: boolean
  ) => void;
  [args: string]: any;
}> = ({ formElement, defaultValue, state, ...rest }) => {
  const [dateTimeValue, setDateTimeValue] = useState<Dayjs | null>(
    defaultValue && typeof defaultValue !== 'string'
      ? dayjs(defaultValue.date! + 'T' + defaultValue.time!)
      : dayjs(new Date().toJSON())
    // ? dateFromString(defaultValue.date!, defaultValue.time!)
    // : new Date()
  );
  const { inputRef, inputError, inputSubmit } = useInput(
    formElement.validator!
  );

  useEffect(() => {
    state(
      formElement,
      inputSubmit,
      inputRef,
      setDateTimeValue,
      defaultValue ? true : false
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        inputFormat="YYYY-MM-DD HH:mm:ss"
        mask="____-__-__ __:__:__"
        ampm={false}
        renderInput={(props: any) => (
          <TextField
            error={inputError}
            helperText={inputError ? formElement.errorText : ''}
            {...props}
          />
        )}
        inputRef={inputRef}
        label={formElement.name as string}
        value={dateTimeValue}
        onChange={(newDateTimeValue: any) => {
          setDateTimeValue(newDateTimeValue);
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerField;
