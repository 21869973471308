import { Fragment, useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import InputTextField from '../../../../../UI/Input';
import MySunEditor from '../../../../../UI/SunEditor';
import InputCheckBox from '../../../../../UI/Checkbox';
import InputFileUploadField from '../../../../../UI/File';
import InputImageUploadField from '../../../../../UI/Image';
import { DragnDrop, DragElement } from '../../../../../UI/DragnDrop';
import DateTimePickerField from '../../../../../UI/DateTimePicker';
import { v4 as uuid } from 'uuid';
import { TreeElement, TreeFormElement } from '../../../../../../config/types';

type Props = {
  action: string;
  newTreeElement: TreeElement;
  parentElement: TreeElement;
  defaultFormElementConfig: TreeFormElement[];
  formElementConfig: TreeFormElement[];
  setFormElementConfig: (
    formElementConfig:
      | TreeFormElement[]
      | ((prevState: TreeFormElement[]) => TreeFormElement[])
  ) => void;
  stateCallBack: (
    state: any,
    code: string,
    inputRef: React.Ref<HTMLInputElement>,
    setStateFn: any,
    touched: boolean | undefined
  ) => void;
  generateFormInputValue: (
    generateFn: (value: string) => string,
    formElement: TreeFormElement
  ) => void;
  inputStates: any;
};

const ElementMap: React.FC<Props> = ({
  action,
  newTreeElement,
  parentElement,
  defaultFormElementConfig,
  formElementConfig,
  setFormElementConfig,
  stateCallBack,
  generateFormInputValue,
  inputStates,
}) => {
  const [optionsUpdated, setOptionsUpdated] = useState(false);
  const [startDragElementOrder, setStartDragElementOrder] = useState<
    number | null
  >(null);

  const createNewOption = (index: number, option: any = null) => {
    const newChildElementsArray = defaultFormElementConfig[
      index
    ].children![0].map(({ ...childElement }) => {
      childElement.id = uuid();
      childElement.context = {};
      if (option) {
        childElement.context = option;
      } else {
        childElement.context.id = uuid();
        childElement.context.order =
          formElementConfig[index].children!.length + 1;
      }
      // childElement.defaultValue = option ? option[childElement.code] : null;
      childElement.defaultValue = childElement.setDefaultValue
        ? childElement.setDefaultValue()
        : childElement.defaultValue;
      return childElement;
    });
    return newChildElementsArray;
  };

  const updateOptions = (
    formElement: TreeFormElement,
    index: number,
    options: any[]
  ) => {
    options.forEach((option: any, optionIndex: number) => {
      if (optionIndex === 0) {
        formElement.children!.forEach(
          (childElementsArray: TreeFormElement[]) => {
            childElementsArray.forEach((childElement: TreeFormElement) => {
              // childElement.defaultValue = option[childElement.code];
              childElement.context = option;
              childElement.defaultValue = childElement.setDefaultValue
                ? childElement.setDefaultValue()
                : childElement.defaultValue;
              // childElement.context.id = option.id;
              // childElement.context.order = option.order;
            });
          }
        );
        return;
      }
      const newChildElementsArray = createNewOption(index, option);
      formElement.children!.push(newChildElementsArray);
    });
  };

  useEffect(() => {
    if (
      newTreeElement.options &&
      newTreeElement.options.length > 0 &&
      formElementConfig.length === defaultFormElementConfig.length
    ) {
      const updatedFormElementConfig = [...formElementConfig].map(
        (formElement: TreeFormElement, index: number) => {
          if (formElement.type === 'nested') {
            updateOptions(formElement, index, newTreeElement.options!);
          }
          return formElement;
        }
      );
      setFormElementConfig(updatedFormElementConfig);
    }
    setOptionsUpdated(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      {formElementConfig.map((formElement: TreeFormElement, index: number) => {
        return (
          <Fragment key={index}>
            <Grid
              item
              xs={
                formElement.size && formElement.size.xs
                  ? formElement.size.xs
                  : 12
              }
              md={
                formElement.size && formElement.size.md
                  ? formElement.size.md
                  : 12
              }
              lg={
                formElement.size && formElement.size.lg
                  ? formElement.size.lg
                  : 12
              }
              sx={formElement.sx && formElement.sx}
            >
              {formElement.type === 'datepicker' && (
                <DateTimePickerField
                  formElement={formElement}
                  defaultValue={
                    action === 'add'
                      ? null
                      : newTreeElement.trdate && newTreeElement.trtime
                      ? {
                          date: newTreeElement.trdate,
                          time: newTreeElement.trtime,
                        }
                      : null
                  }
                  state={stateCallBack}
                  autoFocus={formElement.autoFocus ?? false}
                />
              )}
              {(formElement.type === 'text' ||
                formElement.type === 'number') && (
                <InputTextField
                  formElement={formElement}
                  defaultValue={
                    action === 'add' ||
                    (action === 'edit' && formElement.placement === 'options')
                      ? formElement.defaultValue
                      : formElement.placement === 'tree'
                      ? (parentElement as any)[formElement.code]
                      : (parentElement as any).data[formElement.code]
                  }
                  state={stateCallBack}
                  autoFocus={formElement.autoFocus ?? false}
                  {...(formElement.rest && formElement.rest)}
                />
              )}
              {formElement.type === 'textEditor' && (
                <MySunEditor
                  formElement={formElement}
                  state={stateCallBack}
                  defaultValue={
                    action === 'add'
                      ? formElement.defaultValue
                      : formElement.placement === 'tree'
                      ? (parentElement as any)[formElement.code]
                      : (parentElement as any).data[formElement.code]
                  }
                />
              )}
              {formElement.type === 'checkbox' && (
                <InputCheckBox
                  formElement={formElement}
                  state={stateCallBack}
                  defaultValue={
                    action === 'add'
                      ? formElement.defaultValue
                      : formElement.placement === 'tree'
                      ? (parentElement as any)[formElement.code]
                      : (parentElement as any).data[formElement.code]
                  }
                />
              )}
              {formElement.type === 'file' && (
                <InputFileUploadField
                  newTreeElement={newTreeElement}
                  formElement={formElement}
                  state={stateCallBack}
                />
              )}
              {formElement.type === 'image' && (
                <InputImageUploadField
                  newTreeElement={newTreeElement}
                  formElement={formElement}
                  state={stateCallBack}
                  inputStates={inputStates}
                />
              )}
              {formElement.type === 'plainText' && (
                <span>{formElement.defaultValue as string}</span>
              )}
              {formElement.type === 'dragndrop' && <DragElement />}
            </Grid>
            {formElement.additional && (
              <Grid item xs={3}>
                <Button
                  color="primary"
                  onClick={() =>
                    generateFormInputValue(
                      formElement.additional.generateFn,
                      formElement
                    )
                  }
                  variant="contained"
                  sx={{ alignItems: 'center' }}
                >
                  Generate
                </Button>
              </Grid>
            )}
            {formElement.type === 'nested' && optionsUpdated && (
              <Fragment>
                <Grid item xs={12}>
                  {formElement.name as string}
                </Grid>
                <Fragment>
                  {formElement.children!.map(
                    (formElementArrayElement: TreeFormElement[]) => {
                      return (
                        <DragnDrop
                          key={formElementArrayElement[0].id}
                          order={formElementArrayElement[0].context.order}
                          parentIndex={index}
                          draggable={!!formElement.draggable}
                          setFormElementConfig={setFormElementConfig}
                          setStartDragElementOrder={setStartDragElementOrder}
                          startDragElementOrder={startDragElementOrder}
                        >
                          <Grid
                            container
                            spacing={1}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              ml: '8px',
                            }}
                          >
                            <ElementMap
                              key={index}
                              action={action}
                              newTreeElement={newTreeElement}
                              parentElement={parentElement}
                              defaultFormElementConfig={
                                defaultFormElementConfig
                              }
                              formElementConfig={formElementArrayElement}
                              setFormElementConfig={setFormElementConfig}
                              stateCallBack={stateCallBack}
                              generateFormInputValue={generateFormInputValue}
                              inputStates={inputStates}
                            />
                          </Grid>
                        </DragnDrop>
                      );
                    }
                  )}
                </Fragment>
                {formElement.multi && (
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      onClick={() => {
                        const newChildElementsArray = createNewOption(index);
                        setFormElementConfig((prevState: TreeFormElement[]) => {
                          const newState = [...prevState];
                          const formElementArrayList = newState[
                            index
                          ].children!.concat([newChildElementsArray]);
                          newState[index].children = formElementArrayList;
                          return newState;
                        });
                      }}
                      variant="contained"
                      sx={{ alignItems: 'center' }}
                    >
                      More
                    </Button>
                  </Grid>
                )}
              </Fragment>
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default ElementMap;
