import API from '../utils/API';
import { setupActions } from './setup-slice';
import { loadingActions } from './loading-slice';
import { errorActions } from './error-slice';
import { noticeActions } from './notice-slice';
import { SetupElement } from '../config/types';

export const getSetup = () => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token)
      return dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async (): Promise<SetupElement[]> => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.get('/admin/setup/')
        .then((response) => {
          return response.data.setup;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      dispatch(loadingActions.isLoading());
      const setup = await sendRequest();
      dispatch(setupActions.set({ setup }));
      dispatch(loadingActions.isNotLoading());
      return setup;
    } catch (error: any) {
      dispatch(loadingActions.isNotLoading());
      dispatch(errorActions.setError(error.message));
    }
  };
};

export const deleteSetupElement = (stid: string | number) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token)
      return dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async (): Promise<any> => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.delete('/admin/setup/' + stid)
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      dispatch(loadingActions.isLoading());
      const data = await sendRequest();
      dispatch(loadingActions.isNotLoading());
      dispatch(noticeActions.setNotice(data.message || 'Element deleted'));
    } catch (error: any) {
      dispatch(loadingActions.isNotLoading());
      dispatch(errorActions.setError(error.message));
    }
  };
};

export const updateSetup = (setup: SetupElement[]) => {
  return async (dispatch: any) => {
    const token: string | null = localStorage.getItem('token');
    if (!token)
      return dispatch(errorActions.setError('Authorization required'));

    const sendRequest = async (): Promise<any> => {
      API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return API.put('/admin/setup/', { setup })
        .then((response) => {
          return response.data;
        })
        .catch((error: any) => {
          throw new Error(error.response.data.message || error.message);
        });
    };

    try {
      dispatch(loadingActions.isLoading());
      const data = await sendRequest();
      dispatch(setupActions.set({ setup: data.setup }));
      dispatch(loadingActions.isNotLoading());
      dispatch(noticeActions.setNotice(data.message || 'Element updated'));
    } catch (error: any) {
      dispatch(loadingActions.isNotLoading());
      dispatch(errorActions.setError(error.message));
    }
  };
};
