import { useState } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useEffect } from 'react';
import useInput from '../../hooks/useInput';
import { TreeFormElement } from '../../config/types';

const InputCheckBox: React.FC<{
  formElement: TreeFormElement;
  defaultValue: string | null;
  state: (
    formElement: TreeFormElement,
    submitState: any,
    inputRef: React.Ref<HTMLInputElement>,
    setStateFn: any,
    touched?: boolean
  ) => void;
  [args: string]: any;
}> = ({ formElement, defaultValue, state, ...rest }) => {
  const { inputRef, inputError: _1, inputSubmit } = useInput(() => true);
  const [checkboxState, setCheckBoxState] = useState(defaultValue);

  useEffect(() => {
    inputRef.current!.value = checkboxState!;
    state(
      formElement,
      inputSubmit,
      inputRef,
      setCheckBoxState,
      defaultValue ? true : false
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormControlLabel
      control={
        <Checkbox
          inputRef={inputRef}
          name={formElement.code}
          checked={checkboxState === 'T' ? true : false}
          onChange={() => {
            setCheckBoxState(checkboxState === 'T' ? 'F' : 'T');
            inputRef.current!.value = checkboxState === 'T' ? 'F' : 'T';
            state(formElement, inputSubmit, inputRef, setCheckBoxState);
          }}
          {...rest}
        />
      }
      label={
        typeof formElement.name === 'function'
          ? formElement.name(checkboxState!)
          : formElement.name
      }
    />
  );
};

export default InputCheckBox;
