import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL!,
  headers: {
    'Content-type': 'application/json',
  },
  responseType: 'json',
});

export const SERVER_HTTP = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL!,
  headers: {
    'Content-type': 'multipart/form-data',
  },
  responseType: 'json',
});

// export const APIMultipart = axios.create({
//   baseURL: process.env.REACT_APP_API_URL!,
//   headers: {
//     'Content-type': 'multipart/form-data',
//   },
//   responseType: 'json',
// });

export default API;
